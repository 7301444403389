import { Box, styled } from '@mui/material';
import { memo, useLayoutEffect } from 'react';
import { useInitializeHereMap } from '../hooks';
import { useMap } from '../store';
import { BasicMapPoint } from '../types';
import {
  createDomMarkerAtPosition,
  createMapIcon,
  plantMapMarkerSvg,
} from '../utils';

type MapProps = {
  height: number;
  onAddPoint: (point: BasicMapPoint) => void;
};

const MapContainer = styled(Box)(({ theme }) => ({
  '& > div': {
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[5],
  },
}));

// eslint-disable-next-line react/display-name
export const AddHoneyFieldMap = memo(({ height, onAddPoint }: MapProps) => {
  const mapRef = useInitializeHereMap({
    enableDragMarkerEvents: true,
    onDragEnd: onAddPoint,
  });
  const map = useMap();

  useLayoutEffect(() => {
    if (!map) return;
    map.addEventListener('tap', (event: H.mapevents.Event) => {
      const coord = map.screenToGeo(
        event.currentPointer.viewportX,
        event.currentPointer.viewportY,
      );

      if (coord) {
        const mapObjects = map.getObjects();
        map.removeObjects(mapObjects);

        const icon = createMapIcon(plantMapMarkerSvg, {
          color: '#EADE56',
          marginLeft: '-7px',
          marginTop: '-40px',
        });

        const marker = createDomMarkerAtPosition(coord, {
          icon,
          data: {},
        });

        marker.draggable = true;

        map.addObject(marker);
        onAddPoint({ lat: coord.lat.toString(), lng: coord.lng.toString() });
      }
    });
  }, [map, onAddPoint]);

  return <MapContainer flexGrow={1} height={height} ref={mapRef} />;
});
