import { useEffect } from 'react';
import {
  useClusterProvider,
  useHiveData,
  useSetHiveData,
  useSetIsUpdatingClusteringData,
} from '../store';
import { createCircleAtPosition } from '../utils';

export const useIsClusteringDataUpdating = () => {
  const clusterProvider = useClusterProvider();
  const setIsUpdating = useSetIsUpdatingClusteringData();
  const hiveData = useHiveData();
  const setHiveData = useSetHiveData();

  useEffect(() => {
    const updateHandler = (event: H.util.Event) => {
      if (event.eventPhase === 2) {
        if (hiveData) {
          const circle = createCircleAtPosition(hiveData.position, 3000);
          clusterProvider?.getObjectsWithin(
            circle.getGeometry() as H.geo.Polygon,
            (markers) => {
              const honeyFieldsInRange = markers.map((m) =>
                m.getData().getData(),
              );
              setHiveData({ position: hiveData.position, honeyFieldsInRange });
            },
            { zoom: 20 },
          );
        }
        setIsUpdating(false);
      }
    };

    clusterProvider?.addEventListener('update', updateHandler);

    return () => clusterProvider?.removeEventListener('update', updateHandler);
  }, [clusterProvider, hiveData, setHiveData, setIsUpdating]);
};
