import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { orderBy } from 'lodash';
import { Dispatch, useCallback } from 'react';
import { useCropCategoriesQuery } from '../queries';

type FilterByCropCategoriesProps = {
  selectedCropCategories: number[];
  setSelectedCropCategories: Dispatch<number[]>;
};

export function FilterByCropCategories({
  selectedCropCategories,
  setSelectedCropCategories,
}: FilterByCropCategoriesProps) {
  const { data: cropCategories } = useCropCategoriesQuery({
    select: (data) => orderBy(data, ['name']),
    refetchOnMount: false,
  });

  const handleToggle = (event: SelectChangeEvent<number[]>) => {
    setSelectedCropCategories(event.target.value as number[]);
  };

  const isAllSelected =
    selectedCropCategories.length === (cropCategories?.length ?? 0);
  const handleToggleShowAll = useCallback(() => {
    const cropCodes = isAllSelected
      ? []
      : cropCategories?.map((cropCategory) => cropCategory.crop_code) ?? [];

    setSelectedCropCategories(cropCodes);
  }, [cropCategories, isAllSelected, setSelectedCropCategories]);

  return (
    <FormControl fullWidth sx={{ flex: 1 }}>
      <InputLabel id='demo-multiple-checkbox-label'>
        Categorii plante
      </InputLabel>
      <Select
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        multiple
        value={selectedCropCategories}
        onChange={handleToggle}
        renderValue={(selected) =>
          selected
            .map(
              (s) => cropCategories?.find((c) => c.crop_code === s)?.name ?? '',
            )
            .join(', ')
        }
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 300,
            },
          },
        }}
      >
        <MenuItem onClick={handleToggleShowAll}>
          <Checkbox checked={isAllSelected} />
          <ListItemText primary='Selectează toate' />
        </MenuItem>
        {cropCategories?.map((category) => (
          <MenuItem key={category.crop_code} value={category.crop_code}>
            <Checkbox
              checked={
                selectedCropCategories.indexOf(category.crop_code) !== -1
              }
            />
            <ListItemText primary={category.name} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        Selectează categoriile de plante de interes
      </FormHelperText>
    </FormControl>
  );
}
