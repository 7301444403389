import { Stack, Skeleton, Typography } from '@mui/material';
import { ReactNode } from 'react';

type DetailsPanelContentProps = {
  data: { label: string; value: ReactNode }[];
  isLoading?: boolean;
};

export function DetailsPanelContent({
  data,
  isLoading,
}: DetailsPanelContentProps) {
  const [header, ...rest] = data;

  return !data.length ? (
    <Typography>Nu există informații</Typography>
  ) : (
    <Stack>
      <Stack alignItems='baseline' direction='row' gap={2} mb={1}>
        <Typography fontWeight={700}>
          {header.label}{' '}
          {typeof header.value === 'string' ? (
            <Typography component='span' variant='caption'>
              {header.value}
            </Typography>
          ) : (
            header.value
          )}
        </Typography>
      </Stack>
      {rest.map(({ label, value }) => (
        <Stack key={`${label}-${value}`} direction='row' gap={2} pl={2}>
          {isLoading ? (
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width='80%' />
          ) : (
            <>
              <Typography>{label}</Typography>
              {typeof value === 'string' ? (
                <Typography fontWeight={700}>{value}</Typography>
              ) : (
                value
              )}
            </>
          )}
        </Stack>
      ))}
    </Stack>
  );
}

DetailsPanelContent.defaultProps = {
  isLoading: false,
};
