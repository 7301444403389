import H from '@here/maps-api-for-javascript';
import { omit } from 'lodash';
import { StateCreator } from 'zustand';
import { MapSlice } from './types';

export const createMapSlice: StateCreator<MapSlice> = (set) => ({
  clearSelectedMarker: () =>
    set((state) => omit(state, 'selectedMarker'), true),
  clearHiveData: () => set((state) => omit(state, 'hiveData'), true),
  disposeMap: () =>
    set(
      (state) => omit(state, ['map', 'ui', 'clusterProvider', 'behavior']),
      true,
    ),
  setClusterProvider: (clusterProvider: H.clustering.Provider) =>
    set({ clusterProvider }),
  setHiveData: (newHiveData) =>
    set(({ hiveData }) => ({
      hiveData: {
        ...hiveData,
        ...newHiveData,
      },
    })),
  setMap: (map?: H.Map) => set({ map }),
  setSelectedMarker: (pk: number) => set({ selectedMarker: pk }),
  setUi: (ui: H.ui.UI) => set({ ui }),
  fullscreen: false,
  toggleFullscreen: () => set((prev) => ({ fullscreen: !prev.fullscreen })),
  setIsUpdatingClusterData: (isUpdating) =>
    set({ isUpdatingClusterData: isUpdating }),
  setBehavior: (behavior: H.mapevents.Behavior) => set({ behavior }),
});
