import { useCallback } from 'react';
import { useMap } from '../store';

export const useRemoveMapObjectsByKey = () => {
  const map = useMap();

  return useCallback(
    (key: string) => {
      if (!map) return;

      const mapObjects = map.getObjects();
      const filteredObjects =
        mapObjects?.filter((object) => object.getData()?.key === key) ?? [];

      map.removeObjects(filteredObjects);
    },
    [map],
  );
};
