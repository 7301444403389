import { Box, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import logoRomapisSrc from '../assets/img/logo_romapis.png';
import { Routes } from '../types';

export function Brand() {
  return (
    <Stack
      component={Link}
      mr='auto'
      py={2}
      to={Routes.Home}
      sx={{ textDecoration: 'none' }}
    >
      <Box display='flex' height={{ xs: 32, md: 40 }}>
        <img src={logoRomapisSrc} alt='ROMAPIS Proiect Agroapis' width='100%' />
      </Box>
      <Typography
        color='text.primary'
        fontSize={{ xs: 20, md: 24 }}
        fontWeight={700}
      >
        Proiect Agroapis
      </Typography>
    </Stack>
  );
}
