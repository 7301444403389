import axios from 'axios';
import { API_URLS } from '../constants';
import { CropCategory, CropCategoryFormData } from '../types';

export const addCropCategory = async (
  cropCategory: CropCategoryFormData,
): Promise<CropCategory> => {
  const { data } = await axios.post(API_URLS.CROP_CATEGORIES, cropCategory);

  return data;
};
