import {
  Stack,
  Typography,
  Autocomplete,
  TextField,
  CircularProgress,
  useTheme,
} from '@mui/material';
import { COUNTIES } from '../constants';
import { useAssociativeFormsQuery } from '../queries';

type AgriculturalistFiltersProps = {
  setAssociativeForm: (associativeForm?: string) => void;
  setCounty: (county?: string) => void;
};

export function AgriculturalistFilters({
  setAssociativeForm,
  setCounty,
}: AgriculturalistFiltersProps) {
  const { typography } = useTheme();

  const { data: associativeForms, isLoading: isLoadingAssociativeForms } =
    useAssociativeFormsQuery();

  return (
    <Stack alignItems='center' direction='row' gap={4}>
      <Typography fontWeight={typography.fontWeightBold}>
        Filtrează după
      </Typography>

      <Autocomplete
        fullWidth
        options={COUNTIES}
        onChange={(_, value) => {
          setCounty(value?.id);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              notched: false,
            }}
            placeholder='Județ'
          />
        )}
        sx={{
          maxWidth: 250,
        }}
      />

      <Autocomplete
        fullWidth
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.pk === value.pk}
        loading={isLoadingAssociativeForms}
        onChange={(_, value) => {
          setAssociativeForm(value?.pk.toString());
        }}
        options={associativeForms ?? []}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoadingAssociativeForms ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
              notched: false,
            }}
            placeholder='Formă asociativă'
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.pk}>
            {option.name}
          </li>
        )}
        sx={{
          maxWidth: 250,
        }}
      />
    </Stack>
  );
}
