import numeral from 'numeral';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { initializeGA } from './analyticsTracker';
import { QueryClientProvider } from './queries';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from './routes';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ThemeContext } from './theme';
import '@fontsource/inter';
import { logRocketInit } from './utils/logRocketInit';

initializeGA();
numeral.register('locale', 'ro', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal(number) {
    return number === 1 ? 'primul' : 'lea';
  },
  currency: {
    symbol: '€',
  },
});
numeral.locale('ro');

logRocketInit();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <QueryClientProvider>
      <ThemeContext>
        <RouterProvider />
      </ThemeContext>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
