import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  AlertProps,
  alpha,
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { omit } from 'lodash';
import { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  // Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useAddHoneyField, useRegisterFarmer } from '../mutations';
import { useCropCategoriesQuery } from '../queries';
import { useMap } from '../store';
import {
  BasicMapPoint,
  FarmerRegistrationForm as FarmerRegistrationFormType,
  FormType,
  HoneyField,
} from '../types';
import { AddHoneyFieldMap } from './AddHoneyFieldMap';
import { RegistrationForm } from './RegistrationForm';

type FarmerRegistrationFormProps = {
  onSwitchFormType: (type: FormType) => void;
};

export function FarmerRegistrationForm({
  onSwitchFormType,
}: FarmerRegistrationFormProps) {
  const [alert, setAlert] = useState<{
    open: boolean;
    message: string;
    severity: AlertProps['severity'];
  }>();
  const [open, setOpen] = useState(false);
  const [honeyFields, setHoneyFields] = useState<Omit<HoneyField, 'pk'>[]>([]);
  const [cropCategory, setCropCategory] = useState<number | null>();
  const [declaredArea, setDeclaredArea] = useState<number | null>(0);
  const [coordinates, setCoordinates] = useState<BasicMapPoint | null>();
  const map = useMap();

  const { control, handleSubmit, reset, ...rest } =
    useForm<FarmerRegistrationFormType>({
      defaultValues: {
        accepted_terms: false,
        county: '',
        identification_number: '',
        description: '',
        email: '',
        honey_fields: [],
        name: '',
        phone_number: '',
        settlement: '',
        status: 0,
      },
      mode: 'onChange',
    });

  const { data: cropCategories, isLoading: isLoadingCropCategories } =
    useCropCategoriesQuery();

  const { mutate: registerFarmer, isLoading: isRegisteringFarmer } =
    useRegisterFarmer();
  const { mutateAsync: addHoneyField, isLoading: isAddingHoneyField } =
    useAddHoneyField();

  const isLoading = isRegisteringFarmer || isAddingHoneyField;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const onSubmit = useCallback<SubmitHandler<FarmerRegistrationFormType>>(
    async (data) => {
      const token = await executeRecaptcha?.();
      if (token) {
        const addedHoneyFields = await Promise.all(
          honeyFields.map((honeyField) => addHoneyField(honeyField)),
        );

        const honeyFieldIds = addedHoneyFields.map(({ pk }) => pk);

        registerFarmer(
          {
            ...omit(data, 'accepted_terms'),
            honey_fields: honeyFieldIds,
          },
          {
            onSuccess: () => {
              reset();
              setAlert({
                open: true,
                message:
                  'Îți mulțumim că te-ai înregistrat. Vom valida formularul în cel mai scurt timp',
                severity: 'success',
              });
            },
          },
        );
      }
    },
    [addHoneyField, executeRecaptcha, honeyFields, registerFarmer, reset],
  );

  const onAddHoneyField = useCallback(() => {
    if (cropCategory && declaredArea && coordinates) {
      setHoneyFields((prevHoneyFields) => [
        ...(prevHoneyFields ?? []),
        {
          crop_category: cropCategory,
          declared_area: declaredArea,
          lat: coordinates.lat,
          lng: coordinates.lng,
          reported_year: new Date().getFullYear(),
        },
      ]);

      const mapObjects = map?.getObjects();
      map?.removeObjects(mapObjects ?? []);
      setCropCategory(null);
      setDeclaredArea(0);
      setCoordinates(null);
    }
  }, [coordinates, cropCategory, declaredArea, map]);

  const onRemoveHoneyField = useCallback((index: number) => {
    setHoneyFields((prevHoneyFields) => {
      prevHoneyFields.splice(index, 1);
      return [...prevHoneyFields];
    });
  }, []);

  return (
    <FormProvider
      control={control}
      handleSubmit={handleSubmit}
      reset={reset}
      {...rest}
    >
      <Stack component='form' onSubmit={handleSubmit(onSubmit)}>
        <RegistrationForm
          isLoading={isLoading}
          lastSectionContent={
            <Grid container spacing={10}>
              <Grid container item xs>
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.crop_code === value.crop_code
                    }
                    loading={isLoadingCropCategories}
                    onChange={(_, selectedCropCategory) =>
                      setCropCategory(selectedCropCategory?.crop_code)
                    }
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    open={open}
                    options={cropCategories ?? []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id='crop_category'
                        label='Cultură'
                        InputLabelProps={{
                          htmlFor: 'crop_category',
                          variant: 'outlined',
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingCropCategories ? (
                                <CircularProgress color='inherit' size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                          notched: false,
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option.crop_code}>
                        {option.name}
                      </li>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='declared_area' variant='outlined'>
                      Suprafață:
                    </InputLabel>
                    <OutlinedInput
                      endAdornment={
                        <InputAdornment position='end'>ha</InputAdornment>
                      }
                      id='declared_area'
                      onChange={(event) => {
                        const area = parseFloat(event.target.value);
                        if (Number.isNaN(area)) {
                          setDeclaredArea(0);
                        } else {
                          setDeclaredArea(area);
                        }
                      }}
                      value={declaredArea}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    disabled={!cropCategory || !declaredArea || !coordinates}
                    onClick={onAddHoneyField}
                    variant='contained'
                  >
                    Adaugă
                  </Button>
                </Grid>
              </Grid>

              <Grid item xs>
                <AddHoneyFieldMap height={300} onAddPoint={setCoordinates} />
              </Grid>

              <Grid item xs={12}>
                <Stack gap={2} maxHeight={300} overflow='auto'>
                  {honeyFields.map(
                    // eslint-disable-next-line camelcase
                    ({ lat, lng, crop_category, declared_area }, index) => (
                      <Stack
                        alignItems='center'
                        border={1}
                        borderColor={alpha('#EE4036', 0.3)}
                        borderRadius={1}
                        direction='row'
                        // eslint-disable-next-line camelcase
                        key={`${lat}-${lng}-${crop_category}`}
                        px={2}
                        py={1}
                      >
                        <Stack flexGrow={1}>
                          <Typography fontWeight={700}>
                            {
                              cropCategories?.find(
                                // eslint-disable-next-line camelcase
                                ({ crop_code }) => crop_category === crop_code,
                              )?.name
                            }{' '}
                            <Typography component='span'>
                              {
                                // eslint-disable-next-line camelcase
                                declared_area
                              }{' '}
                              ha
                            </Typography>
                          </Typography>
                          <Typography>
                            {lat.substring(0, 7)}, {lng.substring(0, 7)}
                          </Typography>
                        </Stack>
                        <IconButton onClick={() => onRemoveHoneyField(index)}>
                          <CloseIcon />
                        </IconButton>
                      </Stack>
                    ),
                  )}
                </Stack>
              </Grid>
            </Grid>
          }
          lastSectionTitle='Despre culturi'
          onSwitchFormType={onSwitchFormType}
          type='farmer'
        />
      </Stack>

      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={6000}
        open={alert?.open}
        onClose={() => setAlert(undefined)}
      >
        <Alert variant='filled' severity={alert?.severity}>
          {alert?.message}
        </Alert>
      </Snackbar>
    </FormProvider>
  );
}
