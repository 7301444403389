import {
  Box,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { Divider } from '../components';

export function TermsPage() {
  return (
    <Stack gap={4} py={5}>
      <Typography
        color='primary'
        fontSize={{ xs: 36, md: 48 }}
        textAlign='center'
        variant='h2'
      >
        Termeni și condiții de utilizare
      </Typography>
      <Typography>
        <strong>Termenii și condițiile de utilizare</strong> stabilesc termenii
        și condițiile generale de utilizare a site-ului{' '}
        <strong>agroapis.polenizare.ro</strong> de către potențialii vizitatori
        sau beneficiari.
      </Typography>
      <Typography>
        <strong>agroapis.polenizare.ro</strong> (în continuare denumit „Site”,
        „Platformă”, ”agroapis.polenizare.ro”) are ca scop identificarea,
        studierea şi punerea în practică a surselor alternative de hrană (nectar
        şi polen) pentru albine şi alte insecte polenizatoare prin introducerea
        în circuitul agricol a unor culturi care să contribuie la îmbunătățirea
        condițiilor de agromediu în contextul limitării surselor de hrană pentru
        acestea datorată extinderii monoculturilor şi a culturilor având
        calităţi melifere diminuate şi cu perioada de înflorire mai scurtă.
      </Typography>

      <Divider />

      <Box>
        <Typography gutterBottom variant='h5'>
          1. Obiectivul Platformei
        </Typography>
        <Typography>
          Scopul proiectului <strong>AGROAPIS</strong> este identificarea,
          studierea şi punerea în practică a surselor alternative de hrană
          (nectar şi polen) pentru albine şi alte insecte polenizatoare prin
          introducerea în circuitul agricol a unor culturi care să contribuie la
          îmbunătățirea condițiilor de agromediu în contextul limitării surselor
          de hrană pentru acestea datorată extinderii monoculturilor şi a
          culturilor având calităţi melifere diminuate şi cu perioada de
          înflorire mai scurtă. albinelor.
        </Typography>
      </Box>

      <Box>
        <Typography gutterBottom variant='h5'>
          2. Datele personale prelucrate
        </Typography>
        <Typography>
          Completând formularele puse la dispoziție în paginile platformei putem
          colecta următoarele date:
        </Typography>
        <List dense>
          <ListItem>
            <ListItemText primary='&bull; Numele și prenumele' />
          </ListItem>
          <ListItem>
            <ListItemText primary='&bull; Adresa de e-mail' />
          </ListItem>
          <ListItem>
            <ListItemText primary='&bull; Numărul de telefon' />
          </ListItem>
        </List>
      </Box>

      <Box>
        <Typography gutterBottom variant='h5'>
          3. Scop și temei
        </Typography>
        <Typography>
          <strong>ROMAPIS</strong> prelucrează datele entităților persoane
          juridice, publice sau private, și persoanelor fizice doar în scopul de
          a le oferi informații despre culturi care să contribuie la
          îmbunătățirea condițiilor de agromediu pentru albine și alte insecte
          polenizatoare.
        </Typography>
      </Box>

      <Box>
        <Typography gutterBottom variant='h5'>
          4. Durata stocării
        </Typography>
        <Typography>
          Datele furnizate sunt stocate temporar pe durata funcționării
          platformei <strong>agroapis.polenizare.ro</strong>.
        </Typography>
      </Box>

      <Box>
        <Typography gutterBottom variant='h5'>
          5. Transmiterea datelor către terți
        </Typography>
        <Typography>
          Datele pot fi transmise către alte instituții sau autorități publice,
          precum și alte organe abilitate ale statului, în baza și în limitele
          prevederilor legale, ca urmare a unor cereri expres formulate de
          acestea.
        </Typography>
      </Box>

      <Box>
        <Typography gutterBottom variant='h5'>
          6. Drepturile tale
        </Typography>
        <Typography>
          În calitate de utilizator al platformei{' '}
          <strong>agroapis.polenizare.ro</strong>, potrivit{' '}
          <strong>Legii nr. 679/2016</strong>, precum și, începând cu 25 mai
          2018, potrivit Regulamentului General privind protecția datelor, ai
          următoarele drepturi:
        </Typography>
        <List dense>
          <ListItem>
            <ListItemText primary='&bull; Dreptul la informare cu privire la prelucrarea datelor;' />
          </ListItem>
          <ListItem>
            <ListItemText primary='&bull; Dreptul de acces la datele furnizate, la cerere și în mod gratuit pentru o solicitare pe an;' />
          </ListItem>
          <ListItem>
            <ListItemText primary='&bull; Dreptul de intervenție, rectificarea, ștergerea sau portabilitatea datelor, la cerere și în mod gratuit;' />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <>
                  &bull; Dreptul de a te adresa Autorității Naționale de
                  Supraveghere privind Prelucrarea Datelor cu Caracter Personal
                  (cu sediul în Bucureşti, sector 1, B-dul G-ral. Gheorghe
                  Magheru,{' '}
                  <Link href='https://www.dataprotection.ro' target='_blank'>
                    www.dataprotection.ro
                  </Link>
                  ) sau justiției, pentru apărarea oricăror drepturi garantate
                  de lege;
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText primary='&bull; Dreptul la opoziţie şi procesul decizional individual automatizat;' />
          </ListItem>
          <ListItem>
            <ListItemText primary='&bull; Începând cu 25 mai 2018, potrivit Regulamentului general privind protecția datelor, dreptul la restricționarea prelucrării.' />
          </ListItem>
        </List>
      </Box>

      <Box>
        <Typography gutterBottom variant='h5'>
          7. Securitate și confidențialitate
        </Typography>

        <Typography gutterBottom>
          Protecția informațiilor în cursul prelucrării datelor colectate este o
          preocupare importantă a ROMAPIS, de aceea toate datele colectate în
          cursul vizitelor pe <strong>agroapis.polenizare.ro</strong> sunt
          prelucrate conform prevederilor legale în vigoare în România. ROMAPIS
          folosește tehnologii de ultimă generație și ia toate măsurile tehnice
          rezonabile pentru transmiterea și păstrarea datelor în condiții de
          deplină securitate și confidențialitate.
        </Typography>
        <Typography gutterBottom>
          Toate datele de pe site sunt stocate și procesate pe servere situate
          în Uniunea Europeană, ce cad sub incidența legislației europene de
          protecție a datelor personale. Nicio informație oferită pe acest site
          nu părăsește teritoriul Uniunii Europene.
        </Typography>
        <Typography gutterBottom>
          Dacă descoperim un incident cu privire la securitatea datelor care
          prezintă un risc pentru drepturile și libertățile utilizatorilor
          noștri, vom notifica Autoritatea Națională de Supraveghere privind
          Prelucrarea Datelor cu Caracter Personale în termen de 72 de ore. Dacă
          incidentul de securitate este de natură să prezinte un risc ridicat
          pentru drepturile și libertățile tale, vei fi, de asemenea, notificat.
        </Typography>
        <Typography gutterBottom>
          Este strict interzisă folosirea acestui Site în scopul distrugerii sau
          alterării lui, a conținutului său, a securității acestuia ori pentru
          discreditarea sau hărțuirea <strong>agroapis.polenizare.ro</strong>{' '}
          sau a <strong>ROMAPIS</strong>.
        </Typography>
        <Typography gutterBottom>
          ROMAPIS va aplica toate măsurile de securitate tehnică și
          organizatorică pentru protejarea datelor asupra cărora deține
          controlul împotriva oricărei situații de manipulare accidentală sau
          intenționată, pierdere, distrugere sau împotriva accesului unei
          persoane neautorizate.
        </Typography>
      </Box>

      <Box>
        <Typography gutterBottom variant='h5'>
          8. Modificări Termeni și Condiții generale de utilizare Site
        </Typography>
        <Typography gutterBottom>
          &quot;Termenii și Condițiile generale de utilizare&quot; constituie,
          în întregime, un acord încheiat între dumneavoastră și ROMAPIS în
          privința utilizării <strong>agroapis.polenizare.ro</strong>.
        </Typography>
        <Typography gutterBottom>
          ROMAPIS își rezervă dreptul de a revizui și aduce la zi &quot;Termenii
          și Condițiile generale de utilizare&quot; în orice moment, fără o
          anunțare sau o acceptare prealabilă a utilizatorilor.
        </Typography>
        <Typography gutterBottom>
          Dacă aveți întrebări cu privire la informațiile cuprinse în această
          pagină vă rugăm să ne scrieți la adresa{' '}
          <Link href='mailto:office.romapis@gmail.com'>
            office.romapis@gmail.com
          </Link>
          .
        </Typography>
      </Box>

      <Box>
        <Typography gutterBottom variant='h5'>
          9. Despre ROMAPIS
        </Typography>
        <Typography gutterBottom>
          Federaţia asociaţiilor apicole din România-ROMAPIS a fost înfiinţată
          la 8 decembrie 2007 prin hotărârea a 5 asociaţii apicole fondatoare.
          De la acea dată numărul membrilor săi a crescut în mod semnificativ,
          reunind în rândurile sale un număr mare de apicultori ce deţin multe
          mii de colonii de albine. Pe parcursul existenţei sale ROMAPIS şi-a
          adus o contribuţie importantă la conceperea Programului Naţional
          Apicol (PNA) 2011-2013 şi la implementarea PNA 2008-2010, multe sute
          de apicultori beneficiind de suportul financiar oferit prin acest
          program al UE prin mijlocirea asociaţiilor membre ROMAPIS.
        </Typography>
        <Typography gutterBottom>
          ROMAPIS este membru cu drepturi depline al APIMONDIA începând cu anul
          2009.
        </Typography>
        <Typography gutterBottom>
          Federaţia asociaţiilor apicole din România-ROMAPIS îşi propune în
          continuare, consecventă cu principiile convenite prin statutul său, să
          activeze eficient pentru apărarea intereselor membrilor săi şi
          colaborarea cu alte organizaţii din domeniu în scopul obţinerii
          progresului în apicultură.
        </Typography>
      </Box>

      <Box>
        <Typography gutterBottom variant='h5'>
          10. Contactează-ne
        </Typography>

        <Typography gutterBottom>
          Pentru orice întrebări sau preocupări, precum și pentru exercitarea
          drepturilor tale legate de prelucrarea datelor de către ROMAPIS, ne
          poți contacta la:
        </Typography>
        <Typography>
          Email:{' '}
          <Link href='mailto:office.romapis@gmail.com'>
            office.romapis@gmail.com
          </Link>
        </Typography>
      </Box>
    </Stack>
  );
}
