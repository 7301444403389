import { Typography, useTheme } from '@mui/material';

type SectionTitleProps = {
  subtitle?: string;
  title: string;
};
export function SectionTitle({ subtitle, title }: SectionTitleProps) {
  const { typography } = useTheme();

  return (
    <>
      <Typography
        fontSize={{
          xs: typography.pxToRem(32),
          md: typography.pxToRem(48),
        }}
        fontWeight={typography.fontWeightBold}
        variant='h3'
      >
        {title}
      </Typography>
      {Boolean(subtitle) && <Typography>{subtitle}</Typography>}
    </>
  );
}
SectionTitle.defaultProps = {
  subtitle: '',
};
