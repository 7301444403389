import axios from 'axios';
import { API_URLS } from '../constants';
import { HoneyField } from '../types';

export type HoneyFieldsQueryParams = {
  reportedYear?: string;
  latGte?: string;
  latLte?: string;
  lngGte?: string;
  lngLte?: string;
  county?: string;
};

export async function fetchHoneyFields(
  params: HoneyFieldsQueryParams,
): Promise<HoneyField[]> {
  const queryParams = new URLSearchParams(params);
  const { data } = await axios.get(`${API_URLS.HONEY_FIELD}?${queryParams}`);

  return data;
}
