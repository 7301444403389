import {
  Alert,
  Button,
  CircularProgress,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { chain, uniq, zip } from 'lodash';
import numeral from 'numeral';
import { useCallback, useMemo, useState } from 'react';
import { useIsClusteringDataUpdating } from '../hooks/useIsClusteringDataUpdating';
import {
  useCropCategoriesDetailsQuery,
  useHoneyFieldNearestAddressQuery,
  useHoneyFieldsQuery,
} from '../queries';
import {
  useClearHiveData,
  useClearSelectedMarker,
  useHiveData,
  useIsUpdatingClusteringData,
  useSelectedDate,
  useUpdateHoneyFields,
} from '../store';
import { getCountyId, makeLocationHref } from '../utils';
import { DetailsPanelContainer } from './DetailsPanelContainer';
import { DetailsPanelContent } from './DetailsPanelContent';
import { DetailsPanelTitle } from './DetailsPanelTitle';

const labels = [
  { label: 'Suprafață', unit: 'ha' },
  { label: 'Potențial melifer', unit: 'kg' },
  { label: 'Încărcare maximă recomandată', unit: 'stupi' },
];

const polliniferousPotential = {
  L: 'mic',
  M: 'mediu',
  H: 'mare',
};

const getFieldsLabels = (honeyFieldsCount: number) =>
  `(${honeyFieldsCount} ${honeyFieldsCount === 1 ? 'câmp' : 'câmpuri'})`;

export function BeeHiveDetailsPanel() {
  const hiveData = useHiveData();
  const clearSelectedMarker = useClearSelectedMarker();
  const clearHiveData = useClearHiveData();

  const lat = hiveData?.position.lat ?? 0;
  const lng = hiveData?.position.lng ?? 0;

  const [county, setCounty] = useState<string>('');
  const { data: nearestAddress, isLoading: isLoadingHoneyFieldNearestAddress } =
    useHoneyFieldNearestAddressQuery(
      {
        lat,
        lng,
      },
      {
        onSuccess: (result) => {
          const countyId = getCountyId(result.address.county);
          if (countyId) {
            setCounty(countyId);
          }
        },
        enabled: Boolean(hiveData?.position),
      },
    );

  const updateHoneyFields = useUpdateHoneyFields();
  const selectedDate = useSelectedDate();
  const { isFetching: isLoadingHoneyFields } = useHoneyFieldsQuery(
    { county, reportedYear: selectedDate?.getFullYear().toString() },
    {
      enabled: Boolean(county),
      onSuccess: (data) => {
        updateHoneyFields(data);
      },
    },
  );

  useIsClusteringDataUpdating();
  const isClusteringDataUpdating = useIsUpdatingClusteringData();

  const cropCodesInRange = useMemo(
    () => uniq(hiveData?.honeyFieldsInRange.map((h) => h.crop_category)) ?? [],
    [hiveData?.honeyFieldsInRange],
  );

  const cropQueries = useCropCategoriesDetailsQuery(cropCodesInRange);

  const isLoadingCropCategories = useMemo(
    () => cropQueries?.some((q) => q.isLoading),
    [cropQueries],
  );

  const cropCategoriesInRange = useMemo(
    () => cropQueries?.map(({ data }) => data) ?? [],
    [cropQueries],
  );

  const groupedHoneyFields = useMemo(
    () =>
      chain(hiveData?.honeyFieldsInRange)
        .groupBy('crop_category')
        .toPairs()
        .orderBy(([_, honeyFields]) => honeyFields.length, 'desc')
        .map(([cropCode, honeyFields]) => {
          const totalArea = honeyFields.reduce(
            (area, honeyField) => area + honeyField.declared_area,
            0,
          );
          const cropCategory = cropCategoriesInRange?.find(
            (c) => c?.crop_code === parseInt(cropCode, 10),
          );

          return [
            {
              label: cropCategory?.name ?? 'Necunoscut',
              value: getFieldsLabels(honeyFields.length),
            },
            {
              label: 'Suprafață',
              value: `${numeral(totalArea).format('0,0.00')} ha`,
            },
            {
              label: 'Potențial melifer',
              value: `${numeral(
                totalArea * (cropCategory?.melliferous_potential ?? 0),
              ).format('0,0')} kg`,
            },
            {
              label: 'Potențial polenifer',
              value: cropCategory?.polliniferous_potential
                ? polliniferousPotential[cropCategory?.polliniferous_potential]
                : '-',
            },
            {
              label: 'Încărcare maximă recomandată',
              value: `${numeral(
                totalArea * (cropCategory?.maximum_load ?? 0),
              ).format('0,0')} stupi`,
            },
          ];
        })
        .value(),
    [cropCategoriesInRange, hiveData?.honeyFieldsInRange],
  );

  const total = useMemo(
    () =>
      zip(
        ...chain(hiveData?.honeyFieldsInRange)
          .groupBy('crop_category')
          .toPairs()
          .map(([cropCode, honeyFields]) => {
            const totalArea = honeyFields.reduce(
              (area, honeyField) => area + honeyField.declared_area,
              0,
            );
            const cropCategory = cropCategoriesInRange?.find(
              (c) => c?.crop_code === parseInt(cropCode, 10),
            );

            return [
              totalArea,
              Math.ceil(totalArea * (cropCategory?.melliferous_potential ?? 0)),
              Math.ceil(totalArea * (cropCategory?.maximum_load ?? 0)),
            ];
          })
          .value(),
      ).map((values, index) => {
        const value = values?.reduce((a, b) => (a ?? 0) + (b ?? 0), 0);
        return {
          label: labels[index].label,
          value: `${numeral(value).format('0,0')} ${labels[index].unit}`,
        };
      }),
    [cropCategoriesInRange, hiveData?.honeyFieldsInRange],
  );

  const onCloseHandler = useCallback(() => {
    clearSelectedMarker();
    clearHiveData();
  }, [clearHiveData, clearSelectedMarker]);

  const isLoading =
    isLoadingHoneyFieldNearestAddress || isLoadingCropCategories;

  return (
    <DetailsPanelContainer onClose={onCloseHandler}>
      <DetailsPanelTitle
        href={makeLocationHref(
          lat,
          lng,
          `${nearestAddress?.address.street}+${nearestAddress?.address.city}`,
        )}
        isLink
        isLoading={isLoading}
        showIcon
        subtitle={
          <>
            <Typography variant='caption'>
              ~{nearestAddress?.distance}m {nearestAddress?.address.street},
              {nearestAddress?.address.city},
            </Typography>
            <Typography textTransform='uppercase' variant='caption'>
              {nearestAddress?.address.county}
            </Typography>
          </>
        }
        title={`${lat.toPrecision(6)}, ${lng.toPrecision(6)}`}
      />

      {isLoadingHoneyFields || isClusteringDataUpdating ? (
        <Stack alignItems='center' justifyContent='center' height={1}>
          <CircularProgress />
          <Typography>Se încarcă...</Typography>
        </Stack>
      ) : (
        <>
          <Alert severity='info' sx={{ fontSize: 12, py: 0 }}>
            Informațiile din această hartă se bazează pe raportările făcute de
            fermieri către APIA
          </Alert>

          {!groupedHoneyFields?.length && (
            <Typography>
              Nu există câmpuri melifere în această zonă. Folosește harta pentru
              a schimba locația.
            </Typography>
          )}

          {!isLoading &&
            groupedHoneyFields?.map((content) => (
              <DetailsPanelContent
                key={`${content[0].value}-${content[0].label}`}
                data={content}
              />
            ))}

          {total.length > 0 && (
            <DetailsPanelContent
              data={[
                {
                  label: 'Total',
                  value: getFieldsLabels(
                    hiveData?.honeyFieldsInRange?.length ?? 0,
                  ),
                },
                ...total,
              ]}
            />
          )}

          <Stack
            alignItems='center'
            bgcolor='white'
            display='flex'
            direction='row'
            position='sticky'
            bottom={-8}
            py={2}
            gap={1}
          >
            <Button
              fullWidth
              href={`http://apisana.polenizare.ro/incidente-raportate?lat=${lat}&lng=${lng}`}
              LinkComponent={Link}
              target='_blank'
              variant='outlined'
            >
              Vezi incidentele din zonă
            </Button>
          </Stack>
        </>
      )}
    </DetailsPanelContainer>
  );
}
