import H from '@here/maps-api-for-javascript';
import { useCallback, useEffect, useRef } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { MAP_CENTER } from '../constants';
import {
  useClearSelectedMarker,
  useDisposeMap,
  useMap,
  useSetHiveData,
  useSetMap,
  useSetMapBehavior,
  useSetUi,
} from '../store';
import { BasicMapPoint } from '../types';
import { isDomMarker } from '../utils';

export const DEFAULT_MAP_OPTIONS: H.Map.Options = {
  center: MAP_CENTER,
  // Romania's most extreme points https://en.wikipedia.org/wiki/List_of_extreme_points_of_Romania
  bounds: new H.geo.Rect(48.15, 20.19, 43.4, 29.4),
  pixelRatio: window.devicePixelRatio || 1,
  zoom: 0,
};

export function useInitializeHereMap({
  enableDragMarkerEvents,
  onDragEnd,
}: {
  enableDragMarkerEvents?: boolean;
  onDragEnd?: (point: BasicMapPoint) => void;
} = {}) {
  const mapRef = useRef<HTMLElement>();
  const newMap = useMap();
  const setMap = useSetMap();
  const setUi = useSetUi();
  const disposeMap = useDisposeMap();
  const setHiveData = useSetHiveData();
  const clearSelectedMarker = useClearSelectedMarker();
  const setMapBehavior = useSetMapBehavior();

  const onResize = useCallback(() => {
    newMap?.getViewPort().resize();
  }, [newMap]);

  useResizeDetector({
    onResize,
    skipOnMount: true,
    targetRef: mapRef,
  });

  useEffect(() => {
    if (!process.env.REACT_APP_HERE_MAPS_API_KEY || !mapRef.current) {
      return undefined;
    }

    const platform = new H.service.Platform({
      apikey: process.env.REACT_APP_HERE_MAPS_API_KEY,
    });

    const layers = platform.createDefaultLayers();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const map = new H.Map(mapRef.current, layers.raster.satellite.map, {
      ...DEFAULT_MAP_OPTIONS,
    });

    const events = new H.mapevents.MapEvents(map);
    const behavior = new H.mapevents.Behavior(events);

    const ui = H.ui.UI.createDefault(map, layers);

    setMap(map);
    setUi(ui);
    setMapBehavior(behavior);

    if (enableDragMarkerEvents) {
      map.addEventListener(
        'dragstart',
        (event: H.mapevents.Event) => {
          const { target, currentPointer } = event;
          if (isDomMarker(target)) {
            const targetPosition = map.geoToScreen(
              target.getGeometry() as H.geo.Point,
            );

            if (targetPosition) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target.offset = new H.math.Point(
                currentPointer.viewportX - targetPosition.x,
                currentPointer.viewportY - targetPosition.y,
              );
            }
            behavior.disable();
          }
        },
        false,
      );

      map.addEventListener(
        'dragend',
        (event: H.mapevents.Event) => {
          const { target, currentPointer } = event;
          if (isDomMarker(target)) {
            behavior.enable();
            const coord = map.screenToGeo(
              currentPointer.viewportX,
              currentPointer.viewportY,
            );

            if (coord) {
              onDragEnd?.({
                lat: coord.lat.toString(),
                lng: coord.lng.toString(),
              });
            }
          }
        },
        false,
      );

      map.addEventListener(
        'drag',
        (event: H.mapevents.Event) => {
          const { target, currentPointer } = event;

          if (isDomMarker(target)) {
            const newCoords = map.screenToGeo(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              currentPointer.viewportX - target.offset.x,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              currentPointer.viewportY - target.offset.y,
            );

            if (newCoords) {
              target.setGeometry(newCoords);
            }
          }
        },
        false,
      );
    }

    return () => {
      map.dispose();
      disposeMap();
    };
  }, [
    clearSelectedMarker,
    disposeMap,
    enableDragMarkerEvents,
    onDragEnd,
    setHiveData,
    setMap,
    setMapBehavior,
    setUi,
  ]);

  return mapRef;
}
