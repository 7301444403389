import { useHoneyFieldsStore, useInstallAppStore, useStore } from './useStore';

export const useMap = () => useStore((state) => state.map);

export const useSetMap = () => useStore((state) => state.setMap);

export const useDisposeMap = () => useStore((state) => state.disposeMap);

export const useSelectedMarker = () =>
  useStore((state) => state.selectedMarker);

export const useSetSelectedMarker = () =>
  useStore((state) => state.setSelectedMarker);

export const useClearSelectedMarker = () =>
  useStore((state) => state.clearSelectedMarker);

export const useSetHiveData = () => useStore((state) => state.setHiveData);

export const useHiveData = () => useStore((state) => state.hiveData);

export const useClearHiveData = () => useStore((state) => state.clearHiveData);

export const useUi = () => useStore((state) => state.ui);

export const useSetUi = () => useStore((state) => state.setUi);

export const useOpenIdentifyPlantResultsDialog = () =>
  useStore((state) => state.openDialog);

export const useIsOpenIdentifyPlantResultsDialog = () =>
  useStore((state) => state.open);

export const useCloseIdentifyPlantResultsDialog = () =>
  useStore((state) => state.closeDialog);

export const useIdentifyPlantResults = () => useStore((state) => state.data);

export const useClusterProvider = () =>
  useStore((state) => state.clusterProvider);
export const useSetClusterProvider = () =>
  useStore((state) => state.setClusterProvider);

export const useFullscreen = () => useStore((state) => state.fullscreen);
export const useToggleFullScreen = () =>
  useStore((state) => state.toggleFullscreen);

export const useInstallAppSlice = () => {
  const isInstalled = useInstallAppStore((state) => state.isInstalled);
  const setIsInstalled = useInstallAppStore((state) => state.setIsInstalled);

  return { isInstalled, setIsInstalled };
};

export const useHoneyFields = () =>
  useHoneyFieldsStore((state) => state.honeyFields);

export const useUpdateHoneyFields = () =>
  useHoneyFieldsStore((state) => state.updateHoneyFields);

export const useSelectedDate = () =>
  useHoneyFieldsStore((state) => state.selectedDate);
export const useSelectedCropCategories = () =>
  useHoneyFieldsStore((state) => state.selectedCropCategories);

export const useSetSelectedDate = () =>
  useHoneyFieldsStore((state) => state.setSelectedDate);
export const useSetSelectedCropCategories = () =>
  useHoneyFieldsStore((state) => state.setSelectedCropCategories);

export const useIsHoneyFieldsHidden = () =>
  useHoneyFieldsStore((state) => state.isHoneyFieldsHidden);
export const useToggleHoneyFieldsHidden = () =>
  useHoneyFieldsStore((state) => state.toggleHoneyFieldsHidden);

export const useIsUpdatingClusteringData = () =>
  useStore((state) => state.isUpdatingClusterData);

export const useSetIsUpdatingClusteringData = () =>
  useStore((state) => state.setIsUpdatingClusterData);

export const useMapBehavior = () => useStore((store) => store.behavior);
export const useSetMapBehavior = () => useStore((store) => store.setBehavior);
