import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchHoneyField } from '../services';
import { HoneyFieldDetails } from '../types';

export function useHoneyFieldQuery(
  pk?: number,
  options?: UseQueryOptions<
    HoneyFieldDetails | undefined,
    Error,
    HoneyFieldDetails | undefined,
    string[]
  >,
) {
  const enabled = Boolean(pk) && (options?.enabled ?? true);

  return useQuery(
    ['honey-field', (pk ?? 0).toString()],
    () => {
      if (!pk) return undefined;

      return fetchHoneyField(pk);
    },
    { ...options, enabled },
  );
}
