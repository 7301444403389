import {
  Box,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useLocation } from 'react-router-dom';
import { BeekeeperRegistrationForm, Divider } from '../components';
import { FarmerRegistrationForm } from '../components/FarmerRegistrationForm';
import { FormType } from '../types';

export function RegistrationPage() {
  const { state } = useLocation();
  const [type, setType] = useState<FormType>(state?.type ?? 'beekeeper');

  return (
    <Stack gap={4} py={5}>
      <Typography
        color='primary'
        fontSize={{ xs: 40, md: 52 }}
        textAlign='center'
        variant='h2'
      >
        Formular de înregistrare
      </Typography>
      <Typography textAlign='justify'>
        Ne dorim ca acest website să funcționeze ca un instrument folositor și
        interactiv. Pentru aceasta îți propunem să te înregistrezi în lista de
        apicultori ai proiectului.
      </Typography>
      <Box>
        <Typography textAlign='justify'>
          Ce poți obține prin înregistrarea în această aplicație:
        </Typography>
        <List dense>
          <ListItem>
            <ListItemText primary='- informare despre modificări/actualizări/noi funcționalități ale aplicației;' />
          </ListItem>
          <ListItem>
            <ListItemText primary='- comunicări importante de interes apicol;' />
          </ListItem>
          <ListItem>
            <ListItemText primary='- alerte despre incidente care îți pot afecta stupina (intoxicații în zona ta, urgențe  de natură patologică, etc).' />
          </ListItem>
        </List>
      </Box>
      <Box>
        <Typography textAlign='justify'>
          Ce se așteaptă de la tine ca membru al listei noastre de apicultori:
        </Typography>
        <List dense>
          <ListItem>
            <ListItemText primary='- colaborare pentru ridicarea calității acestei aplicații-orice propunere este binevenită;' />
          </ListItem>
          <ListItem>
            <ListItemText primary='- transmiterea de informații de interes apicol major pentru întreaga comunitate (anunțurile comerciale și publicitatea sunt excluse);' />
          </ListItem>
          <ListItem>
            <ListItemText primary='- în limita disponibilității, competenței și pasiunii, participarea pe criteriile științei participative la îmbogățirea bazei de date a aplicației. Exemplu: dacă sunteți botanist profesionist sau iubitor pasionat al plantelor melifere puteți contribui la îmbunătățirea aplicației de recunoaștere a plantelor melifere (faci o poză a unei plante, mergi la pagina „Acasă”, o încarci în sistem împreună cu datele botanice, melifere și/sau agronomice, ca propunere pentru includere în baza de date comună).' />
          </ListItem>
        </List>
      </Box>
      <Divider />

      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_CAPTCHA_API_KEY ?? ''}
        language='ro'
      >
        {type === 'beekeeper' && (
          <BeekeeperRegistrationForm onSwitchFormType={setType} />
        )}

        {type === 'farmer' && (
          <FarmerRegistrationForm onSwitchFormType={setType} />
        )}
      </GoogleReCaptchaProvider>
    </Stack>
  );
}
