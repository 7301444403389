import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { values } from 'lodash';
import { fetchHoneyFields, HoneyFieldsQueryParams } from '../services';
import { HoneyField } from '../types';

const defaultQueryParams: HoneyFieldsQueryParams = {
  reportedYear: new Date().getFullYear().toString(),
};

export function useHoneyFieldsQuery(
  queryParams: HoneyFieldsQueryParams,
  options?: UseQueryOptions<HoneyField[], unknown, HoneyField[]>,
) {
  const params = { ...defaultQueryParams, ...queryParams };

  return useQuery({
    queryKey: ['honey-fields', ...values(params)],
    queryFn: () => fetchHoneyFields(params),
    ...options,
    enabled: options?.enabled ?? false,
  });
}
