const SITE_URL = process.env.REACT_APP_DJANGO_SITE_URL;
const PORT = process.env.REACT_APP_DJANGO_PORT;
const API_ENDPOINT = process.env.REACT_APP_DJANGO_API_ENDPOINT;
const BASE_URL = `${SITE_URL}:${PORT}/${API_ENDPOINT}`;

export const API_URLS = {
  ASSOCIATIVE_FORMS: `${BASE_URL}/associative_forms/`,
  BEEKEEPERS: `${BASE_URL}/beekeepers/`,
  CONTACT: `${BASE_URL}/contact/`,
  CROP_CATEGORIES: `${BASE_URL}/crop_categories/`,
  FARMERS: `${BASE_URL}/farmers/`,
  HONEY_FIELD: `${BASE_URL}/honey_field/`,
  STATS: `${BASE_URL}/stats/`,
};
