import { darken } from '@mui/material';
import { chain } from 'lodash';
import { useCallback, useRef } from 'react';
import { PRIMARY } from '../constants';
import { useCropCategoriesQuery } from '../queries';
import {
  useClearHiveData,
  useMap,
  useSetClusterProvider,
  useSetSelectedMarker,
  useUi,
} from '../store';
import { HoneyField } from '../types';
import {
  buildClusterLayer,
  createMapIcon,
  plantMapMarkerSvg,
  treeMapMakerSvg,
} from '../utils';

// const MARKER_ZOOM = 17;

const getIcon = (isAnnualPlant: boolean, selected: boolean) => {
  const svgMarker = isAnnualPlant ? plantMapMarkerSvg : treeMapMakerSvg;
  const iconColor = isAnnualPlant ? '#F99771' : '#0BC58B';

  return createMapIcon(svgMarker, {
    color: selected ? darken(iconColor, 0.3) : iconColor,
    marginLeft: '-7px',
    marginTop: '-40px',
  });
};

export const useBuildClusterLayer = () => {
  const map = useMap();
  const ui = useUi();
  const setSelectedMarker = useSetSelectedMarker();
  const setClusterProvider = useSetClusterProvider();
  const clearHiveData = useClearHiveData();
  const { data: cropCategories } = useCropCategoriesQuery();
  const prevSelectedMarker = useRef<H.map.DomMarker>();

  const onNoiseMarkerTap = useCallback(
    (marker: H.map.DomMarker) => {
      if (!map) return;

      // const mapZoom = map.getZoom();
      // const markerZoom = mapZoom > MARKER_ZOOM ? mapZoom : MARKER_ZOOM;

      map.getViewModel().setLookAtData(
        {
          position: marker.getData().getPosition(),
          // zoom: markerZoom,
        },
        true,
      );

      const honeyField: HoneyField = marker.getData().getData();

      clearHiveData();
      setSelectedMarker(honeyField.pk);

      const newData = marker.getData().getData();
      const prevData = prevSelectedMarker.current?.getData().getData();
      if (newData.pk !== prevData.pk) {
        marker.setIcon(getIcon(newData.lifecycle === 'A', true));

        prevSelectedMarker.current?.setIcon(
          getIcon(prevData.lifecycle === 'A', false),
        );
        prevSelectedMarker.current = marker;
      }
    },
    [clearHiveData, map, setSelectedMarker],
  );

  const onClusterMarkerTap = useCallback(
    (marker: H.map.DomMarker, honeyFields: HoneyField[]) => {
      if (!map) return;

      map.getViewModel().setLookAtData(
        {
          position: marker.getData().getPosition(),
          // zoom: map.getZoom() + 2,
        },
        true,
      );

      const bubbleContainer = document.createElement('div');
      const cropsContainer = document.createElement('div');
      cropsContainer.style.maxHeight = '200px';
      cropsContainer.style.width = '320px';
      cropsContainer.style.overflow = 'auto';
      chain(honeyFields)
        .groupBy('crop_category')
        .toPairs()
        .orderBy(([_, fields]) => fields.length, 'desc')
        .forEach(([code, fields]) => {
          const cropText = document.createElement('p');
          cropText.innerText = `• ${
            cropCategories?.find(
              (cropCategory) => cropCategory.crop_code === parseInt(code, 10),
            )?.name
          } (${fields.length})`;
          cropText.style.margin = '0';
          cropText.style.fontFamily = 'Inter';
          cropText.style.fontWeight = '400';
          cropText.style.fontSize = '0.875rem';
          cropText.style.lineHeight = '1.5';

          cropsContainer.appendChild(cropText);
        })
        .value();

      bubbleContainer.appendChild(cropsContainer);
      const showMore = document.createElement('button');
      showMore.innerText = 'Mărește zona';
      showMore.style.backgroundColor = 'rgba(22, 163, 74, 0.04)';
      showMore.style.outline = '0';
      showMore.style.border = '0';
      showMore.style.cursor = 'pointer';
      showMore.style.color = PRIMARY;
      showMore.style.fontFamily = 'Inter';
      showMore.style.fontWeight = '500';
      showMore.style.fontSize = '0.8125rem';
      showMore.style.lineHeight = '1.75';
      showMore.style.textTransform = 'uppercase';
      showMore.style.minWidth = '64px';
      showMore.style.padding = '4px 5px';
      showMore.style.borderRadius = '4px';
      showMore.style.marginLeft = 'auto';
      showMore.onclick = () => {
        ui?.getBubbles().forEach((bubble) => ui.removeBubble(bubble));
        map.setZoom(map.getZoom() + 2, true);
      };

      bubbleContainer.appendChild(showMore);

      ui?.getBubbles().forEach((bubble) => ui.removeBubble(bubble));
      const infoBubble = new H.ui.InfoBubble(marker.getData().getPosition(), {
        content: bubbleContainer,
      });

      ui?.addBubble(infoBubble);
    },
    [cropCategories, map, ui],
  );

  return useCallback(
    (data: HoneyField[]) => {
      if (!map) return;

      const { clusterLayer, clusterProvider } = buildClusterLayer<HoneyField>(
        data,
        onClusterMarkerTap,
        onNoiseMarkerTap,
      );

      setClusterProvider(clusterProvider);
      map.addLayer(clusterLayer);
    },
    [map, onClusterMarkerTap, onNoiseMarkerTap, setClusterProvider],
  );
};
