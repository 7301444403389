import axios from 'axios';
import { API_URLS } from '../constants';
import { FarmerRegistrationForm } from '../types';

export const registerFarmer = async (
  registerForm: Omit<FarmerRegistrationForm, 'accepted_terms'>,
): Promise<Omit<FarmerRegistrationForm, 'accepted_terms'>> => {
  const { data } = await axios.post(API_URLS.FARMERS, registerForm);

  return data;
};
