import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';
import { pickBy } from 'lodash';
import { API_URLS } from '../constants';
import { Beekeeper, FetchApiResponse } from '../types';

const LIMIT = '25';

export async function fetchBeekeepers({
  pageParam = 0,
  queryKey,
}: QueryFunctionContext<string[]>): Promise<FetchApiResponse<Beekeeper>> {
  const [, county, associativeForm] = queryKey;
  const filters = pickBy(
    { county, associativeForm },
    (value) => value !== undefined && value !== '',
  );

  const queryParams = new URLSearchParams({
    ...filters,
    limit: LIMIT,
    offset: pageParam,
  });

  const { data } = await axios.get(`${API_URLS.BEEKEEPERS}?${queryParams}`);

  return data;
}
