import { createBrowserRouter } from 'react-router-dom';
import {
  AboutPage,
  BeekeepersPage,
  ContactPage,
  FarmersPage,
  HomePage,
  InstallInstructionsPage,
  // PolicyPage,
  RegistrationPage,
  Root,
  TermsPage,
} from '../pages';
import { Routes } from '../types';

export const router = createBrowserRouter([
  {
    path: Routes.Home,
    element: <Root />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: Routes.AboutProject,
        element: <AboutPage />,
      },
      {
        path: Routes.FarmersList,
        element: <FarmersPage />,
      },
      {
        path: Routes.BeeKeepersList,
        element: <BeekeepersPage />,
      },
      {
        path: Routes.Registration,
        element: <RegistrationPage />,
      },
      {
        path: Routes.Contact,
        element: <ContactPage />,
      },
      // {
      //   path: Routes.Policy,
      //   element: <PolicyPage />,
      // },
      {
        path: Routes.Terms,
        element: <TermsPage />,
      },
      {
        path: Routes.InstallInstructions,
        element: <InstallInstructionsPage />,
      },
    ],
  },
]);
