import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import {
  IconButton,
  Link,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';

type DetailsPanelTitle = {
  href?: string;
  isLink: boolean;
  isLoading: boolean;
  showIcon: boolean;
  subtitle: ReactNode;
  title: string;
};

export function DetailsPanelTitle({
  href = '',
  isLink,
  isLoading,
  showIcon,
  subtitle,
  title,
}: DetailsPanelTitle) {
  const subtitleContent =
    typeof subtitle === 'string' ? (
      <Typography variant='caption'>{subtitle}</Typography>
    ) : (
      subtitle
    );

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(title);
  };

  return (
    <Stack alignItems='center' direction='row' gap={2}>
      {showIcon && <LocationOnOutlinedIcon />}
      <Stack sx={{ flexGrow: 1 }}>
        {isLoading ? (
          <Skeleton variant='text' sx={{ fontSize: '1.25rem' }} />
        ) : (
          <Stack alignItems='center' direction='row' gap={1}>
            <Typography
              component={isLink ? Link : 'div'}
              href={isLink ? href : undefined}
              fontWeight={700}
              sx={{ cursor: isLink ? 'pointer' : 'initial' }}
              target={isLink ? '_blank' : undefined}
              variant='h6'
            >
              {title}
            </Typography>
            <Tooltip arrow title='Copiază coordonate'>
              <IconButton onClick={handleCopyToClipboard} size='small'>
                <ContentCopyRoundedIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
        <Stack direction='row' gap={1}>
          {isLoading ? (
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width='100%' />
          ) : (
            subtitleContent
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

DetailsPanelTitle.defaultProps = {
  href: '',
};
