import { chain, orderBy } from 'lodash';
import { useCropCategoriesQuery } from '../queries';
import {
  useSelectedCropCategories,
  useSelectedDate,
  useSetSelectedCropCategories,
  useSetSelectedDate,
} from '../store';
import { FilterByCropCategories } from './FilterByCropCategories';
import { FilterByFloweringDate } from './FilterByFloweringDate';

export function FilterMenu() {
  // const { breakpoints } = useTheme();
  // const isMobile = useMediaQuery(breakpoints.down('sm'));

  const selectedCropCategories = useSelectedCropCategories();
  const setSelectedCropCategories = useSetSelectedCropCategories();
  useCropCategoriesQuery({
    onSuccess: (data) => {
      const cropCodes = chain(data)
        .map((cropCategory) => cropCategory.crop_code)
        .value();

      setSelectedCropCategories(cropCodes);
    },
    select: (data) => orderBy(data, ['name']),
  });
  // const clusterProvider = useClusterProvider();

  const selectedDate = useSelectedDate();
  const setSelectedDate = useSetSelectedDate();
  // const [selectedDate, setSelectedDate] = useState<Date | null>(
  //   new Date(Date.now()),
  // );
  // const clearHiveData = useClearHiveData();
  // const handleApplyFilter: MouseEventHandler = useCallback(
  //   (event) => {
  //     event.stopPropagation();
  //     event.preventDefault();
  //     const cropCodesByDate = filter(cropCategories, (cropCategory) => {
  //       const bloomStart = new Date(cropCategory.blooming_period_start);
  //       const bloomEnd = new Date(cropCategory.blooming_period_end);

  //       return (
  //         !selectedDate ||
  //         (bloomStart.getTime() <= selectedDate.getTime() &&
  //           selectedDate.getTime() <= bloomEnd.getTime())
  //       );
  //     }).map((cropCategory) => cropCategory.crop_code);

  //     const cropCodes = filter(selectedCropCategories, (code) =>
  //       cropCodesByDate.includes(code),
  //     );
  //     const filteredHoneyFields = filter(honeyFields, (field) =>
  //       cropCodes.includes(field.crop_category),
  //     );

  //     const dataPoints = filteredHoneyFields.map(
  //       (item) =>
  //         new H.clustering.DataPoint(
  //           parseFloat(item.lat),
  //           parseFloat(item.lng),
  //           1,
  //           {
  //             ...item,
  //             lifecycle:
  //               find(
  //                 cropCategories,
  //                 (cropCategory) =>
  //                   cropCategory.crop_code === item.crop_category,
  //               )?.lifecycle ?? 'A',
  //           },
  //         ),
  //     );
  //     clusterProvider?.setDataPoints(dataPoints);
  //     clearHiveData();
  //   },
  //   [
  //     clearHiveData,
  //     clusterProvider,
  //     cropCategories,
  //     honeyFields,
  //     selectedCropCategories,
  //     selectedDate,
  //   ],
  // );

  return (
    <>
      <FilterByFloweringDate
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <FilterByCropCategories
        selectedCropCategories={selectedCropCategories}
        setSelectedCropCategories={setSelectedCropCategories}
      />

      {/* <Button
        fullWidth={isMobile}
        onClick={handleApplyFilter}
        variant='contained'
      >
        Afișează
      </Button> */}
    </>
  );
}
