import {
  Box,
  CircularProgress,
  darken,
  lighten,
  Stack,
  SvgIconProps,
  Typography,
  useTheme,
} from '@mui/material';
import numeral from 'numeral';
import { CardCornerIcon } from './Icons';

type StatisticsCardProps = {
  color: string;
  count: number;
  icon: (props: SvgIconProps) => JSX.Element;
  isLoading: boolean;
  label: string;
  unit?: string;
};

export function StatisticsCard({
  color,
  count,
  icon: Icon,
  isLoading,
  label,
  unit,
}: StatisticsCardProps) {
  const { typography } = useTheme();
  return (
    <Stack
      bgcolor={color}
      borderRadius={2}
      boxShadow={3}
      flexGrow={1}
      height={140}
      position='relative'
      px={9}
      py={7}
    >
      {isLoading ? (
        <Box
          alignItems='center'
          display='flex'
          height={1}
          justifyContent='center'
        >
          <CircularProgress color='info' size={20} />
        </Box>
      ) : (
        <>
          <Typography fontWeight={700} variant='h3'>
            {numeral(count).format()}
            {unit && (
              <Typography component='span' fontSize={20} fontWeight='bold'>
                {unit}
              </Typography>
            )}
          </Typography>
          <Typography fontSize={typography.pxToRem(18)} fontWeight={700}>
            {label}
          </Typography>
        </>
      )}
      <Box color={lighten(color, 0.55)} position='absolute' right={0} top={0}>
        <CardCornerIcon />
        <Icon
          sx={{
            color: darken(color, 0.35),
            position: 'absolute',
            right: 12,
            top: 8,
          }}
        />
      </Box>
    </Stack>
  );
}

StatisticsCard.defaultProps = {
  unit: '',
};
