import { Alert, Typography } from '@mui/material';
import numeral from 'numeral';
import { ReactNode, useCallback, useMemo } from 'react';
import {
  useHoneyFieldQuery,
  useHoneyFieldNearestAddressQuery,
} from '../queries';
import {
  useSelectedMarker,
  useClearSelectedMarker,
  useClearHiveData,
} from '../store';
import { makeLocationHref } from '../utils';
import { DetailsPanelContainer } from './DetailsPanelContainer';
import { DetailsPanelContent } from './DetailsPanelContent';
import { DetailsPanelTitle } from './DetailsPanelTitle';

export function HoneyFieldDetailsPanel() {
  const selectedMarker = useSelectedMarker();

  const clearSelectedMarker = useClearSelectedMarker();
  const clearHiveData = useClearHiveData();
  const {
    data,
    isSuccess,
    isLoading: isLoadingHoneyField,
  } = useHoneyFieldQuery(selectedMarker);

  const lat = parseFloat(data?.lat ?? '');
  const lng = parseFloat(data?.lng ?? '');
  const { data: nearestAddress, isLoading: isLoadingHoneyFieldNearestAddress } =
    useHoneyFieldNearestAddressQuery(
      {
        lat,
        lng,
      },
      { enabled: isSuccess },
    );

  const isLoading = isLoadingHoneyField || isLoadingHoneyFieldNearestAddress;

  const content = useMemo<{ label: string; value: ReactNode }[]>(
    () =>
      data
        ? [
            {
              label: data.crop_category.name,
              value: `(${data.crop_category.latin_name})`,
            },
            {
              label: 'Suprafață totală',
              value: `${numeral(data.declared_area).format('0,0.00')} ha`,
            },
            {
              label: 'Potențial melifer',
              value: `${numeral(
                (data.crop_category.melliferous_potential ?? 0) *
                  data.declared_area,
              ).format('0,0.00')} kg`,
            },
            {
              label: 'Potențial polenifer',
              value: data.crop_category.polliniferous_potential ?? '-',
            },
            {
              label: 'Încărcare maximă recomandată',
              value: `${numeral(
                (data.crop_category.maximum_load ?? 0) * data.declared_area,
              ).format('0,0.00')} stupi`,
            },
          ]
        : [],
    [data],
  );

  const onCloseHandler = useCallback(() => {
    clearSelectedMarker();
    clearHiveData();
  }, [clearHiveData, clearSelectedMarker]);

  return (
    <DetailsPanelContainer onClose={onCloseHandler}>
      <DetailsPanelTitle
        href={makeLocationHref(
          lat,
          lng,
          `${nearestAddress?.address.street}+${nearestAddress?.address.city}`,
        )}
        isLink
        isLoading={isLoading}
        showIcon
        subtitle={
          <>
            <Typography variant='caption'>
              ~{nearestAddress?.distance}m {nearestAddress?.address.street},
              {nearestAddress?.address.city},
            </Typography>
            <Typography textTransform='uppercase' variant='caption'>
              {nearestAddress?.address.county}
            </Typography>
          </>
        }
        title={`${lat.toPrecision(6)}, ${lng.toPrecision(6)}`}
      />
      {!isLoading && (
        <Alert severity='info' sx={{ fontSize: 12, py: 0 }}>
          Informațiile din această hartă se bazează pe raportările făcute de
          fermieri către APIA
        </Alert>
      )}

      <DetailsPanelContent data={content} isLoading={isLoading} />
    </DetailsPanelContainer>
  );
}
