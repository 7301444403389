export enum Routes {
  AboutProject = '/despre',
  BeeKeepersList = '/apicultori',
  Contact = '/contact',
  FarmersList = '/fermieri',
  Home = '/',
  // Policy = '/politica-confidentialitate',
  Registration = '/inregistrare',
  Terms = '/termeni-conditii',
  InstallInstructions = '/instructiuni-instalare',
}

type RouteConfig = {
  label: string;
  route: Routes;
  isFooterLink?: boolean;
};

export type RoutesConfig = RouteConfig[];
