import H from '@here/maps-api-for-javascript';
import { useCallback, useRef } from 'react';
import { BEE_HIVE_KEY } from '../constants';
import {
  useClearSelectedMarker,
  useClusterProvider,
  useMap,
  useSetHiveData,
} from '../store';
import { createBeeHiveAtPosition, createCircleAtPosition } from '../utils';
import { useRemoveMapObjectsByKey } from './useRemoveMapObjectsByKey';

export const useShowBeeHiveAtPosition = () => {
  const map = useMap();
  const circleRef = useRef<H.map.Circle>();
  const setHiveData = useSetHiveData();
  const clearSelectedMarker = useClearSelectedMarker();
  const removeMapObjectsByKey = useRemoveMapObjectsByKey();
  const clusterProvider = useClusterProvider();

  const onBeeHiveTap = useCallback(() => {
    clearSelectedMarker();
    if (!circleRef.current) return;
    map
      ?.getViewModel()
      .setLookAtData(
        { bounds: circleRef.current.getBoundingBox() ?? undefined },
        true,
      );
    clusterProvider?.getObjectsWithin(
      circleRef.current.getGeometry() as H.geo.Polygon,
      (markers) => {
        const honeyFieldsInRange = markers.map((m) => m.getData().getData());
        if (!circleRef.current) return;
        setHiveData({
          position: circleRef.current.getCenter(),
          honeyFieldsInRange,
        });
      },
      { zoom: 20 },
    );
  }, [clearSelectedMarker, clusterProvider, map, setHiveData]);

  return useCallback(
    (position: H.geo.IPoint) => {
      clearSelectedMarker();
      removeMapObjectsByKey(BEE_HIVE_KEY);

      const beeHive = createBeeHiveAtPosition(position, onBeeHiveTap);

      map?.addObject(beeHive);
      map?.getViewModel().setLookAtData({
        bounds: beeHive.getBoundingBox(),
      });

      circleRef.current = createCircleAtPosition(position, 3000);

      clusterProvider?.getObjectsWithin(
        circleRef.current.getGeometry() as H.geo.Polygon,
        (markers) => {
          const honeyFieldsInRange = markers.map((m) => m.getData().getData());
          setHiveData({ position, honeyFieldsInRange });
        },
        { zoom: 20 },
      );
    },
    [
      clearSelectedMarker,
      clusterProvider,
      map,
      onBeeHiveTap,
      removeMapObjectsByKey,
      setHiveData,
    ],
  );
};
