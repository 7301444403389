import { Stack } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ro } from 'date-fns/locale';
import { Dispatch, useCallback } from 'react';

type FilterByFloweringDateProps = {
  selectedDate: Date | null;
  setSelectedDate: Dispatch<Date | null>;
};

export function FilterByFloweringDate({
  selectedDate,
  setSelectedDate,
}: FilterByFloweringDateProps) {
  const handleDateChange = useCallback(
    (date: Date | null) => {
      setSelectedDate(date);
    },
    [setSelectedDate],
  );

  return (
    <Stack alignItems='flex-end' direction='row' flex={1} width={1}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ro}
        localeText={{
          nextMonth: 'Luna următoare',
          previousMonth: 'Luna anterioară',
        }}
      >
        <DatePicker
          label='Data evaluării potențialului'
          onChange={handleDateChange}
          slotProps={{
            textField: {
              fullWidth: true,
              InputProps: {
                notched: false,
              },
              helperText:
                'Alege data pentru a vedea culturile aflate în perioada de înflorire',
            },
          }}
          value={selectedDate}
          sx={{
            width: 1,
          }}
        />
      </LocalizationProvider>
    </Stack>
  );
}
