import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchCropCategory } from '../services';
import { CropCategory } from '../types';

export function useCropCategoryQuery(
  id?: number,
  options?: UseQueryOptions<
    CropCategory | undefined,
    Error,
    CropCategory | undefined,
    string[]
  >,
) {
  const enabled = (!!id && options?.enabled) ?? true;

  return useQuery(
    ['crop-category', id?.toString() ?? ''],
    () => fetchCropCategory(id ?? 0),
    {
      ...options,
      enabled,
    },
  );
}
