import {
  Alert,
  AlertProps,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ro } from 'date-fns/locale';
import { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useAddCropCategory } from '../mutations';
import { CropCategoryFormData } from '../types';

const POLLINIFEROUS_POTENTIAL = {
  L: 'Scăzut',
  M: 'Mediu',
  H: 'Crescut',
};

const WATER_REQUIREMENT = {
  L: 'Scăzută',
  M: 'Medie',
  H: 'Crescută',
};

type PlantDetailsFormProps = {
  commonName?: string;
  latinName?: string;
  onSuccess?: () => void;
};

export function PlantDetailsForm({
  commonName,
  latinName,
  onSuccess,
}: PlantDetailsFormProps) {
  const { control, handleSubmit, setValue, formState, reset } =
    useForm<CropCategoryFormData>({
      mode: 'all',
      defaultValues: {
        name: commonName,
        crop_code: Math.ceil(Date.now() / 1000),
        latin_name: latinName,
        polliniferous_potential: 'L',
        melliferous_potential: 0,
        maximum_load: 0,
        water_requirement: 'L',
      },
      reValidateMode: 'onChange',
    });

  const { executeRecaptcha } = useGoogleReCaptcha();
  const { mutate: addCropCategory, isLoading } = useAddCropCategory();
  const [notify, setNotify] = useState<{
    open: boolean;
    severity?: AlertProps['severity'];
    message: string;
  }>({
    open: false,
    severity: 'success',
    message: '',
  });

  const onSubmit = useCallback<SubmitHandler<CropCategoryFormData>>(
    async (data) => {
      const token = await executeRecaptcha?.();
      if (token) {
        addCropCategory(data, {
          onError: () => {
            setNotify({
              open: true,
              severity: 'error',
              message: 'Am întâmpinat o problemă. Încearcă din nou',
            });
          },
          onSuccess: () => {
            reset();
            setNotify({
              open: true,
              severity: 'success',
              message:
                'Îți mulțumim! Caracteristicile plantei urmează să fie validate',
            });
            onSuccess?.();
          },
        });
      }
    },
    [addCropCategory, executeRecaptcha, onSuccess, reset],
  );

  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)}>
      <Grid container columnSpacing={4}>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name='name'
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor={field.name} variant='outlined'>
                  Nume comun*:
                </InputLabel>
                <OutlinedInput
                  error={Boolean(fieldState.error)}
                  id={field.name}
                  {...field}
                />
                <FormHelperText error={Boolean(fieldState.error)}>
                  {fieldState.error?.message ?? ' '}
                </FormHelperText>
              </FormControl>
            )}
            rules={{
              required: {
                value: true,
                message: 'Numele comun este necesar',
              },
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name='latin_name'
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor={field.name} variant='outlined'>
                  Nume latin*:
                </InputLabel>
                <OutlinedInput
                  error={Boolean(fieldState.error)}
                  id={field.name}
                  {...field}
                />
                <FormHelperText error={Boolean(fieldState.error)}>
                  {fieldState.error?.message ?? ' '}
                </FormHelperText>
              </FormControl>
            )}
            rules={{
              required: {
                value: true,
                message: 'Numele latin este necesar',
              },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6'>Caracteristici apicole</Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name='blooming_period_start'
            render={({ fieldState }) => (
              <FormControl fullWidth>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={ro}
                  localeText={{
                    nextMonth: 'Luna următoare',
                    previousMonth: 'Luna anterioară',
                  }}
                >
                  <DatePicker
                    label='Început perioadă de inflorire:'
                    onChange={(date: Date | null) => {
                      if (!date) return;
                      setValue(
                        'blooming_period_start',
                        format(date, 'yyyy-MM-dd'),
                      );
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        InputProps: {
                          notched: false,
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
                <FormHelperText error={Boolean(fieldState.error)}>
                  {fieldState.error?.message ?? ' '}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name='blooming_period_end'
            render={({ fieldState }) => (
              <FormControl fullWidth>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={ro}
                  localeText={{
                    nextMonth: 'Luna următoare',
                    previousMonth: 'Luna anterioară',
                  }}
                >
                  <DatePicker
                    label='Sfârșit perioadă de inflorire:'
                    onChange={(date: Date | null) => {
                      if (!date) return;
                      setValue(
                        'blooming_period_end',
                        format(date, 'yyyy-MM-dd'),
                      );
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        InputProps: {
                          notched: false,
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
                <FormHelperText error={Boolean(fieldState.error)}>
                  {fieldState.error?.message ?? ' '}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name='melliferous_potential'
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor={field.name} variant='outlined'>
                  Potențial melifer:
                </InputLabel>
                <OutlinedInput
                  endAdornment={
                    <InputAdornment position='end'>kg/ha</InputAdornment>
                  }
                  error={Boolean(fieldState.error)}
                  id={field.name}
                  type='number'
                  {...field}
                />
                <FormHelperText error={Boolean(fieldState.error)}>
                  {fieldState.error?.message ?? ' '}
                </FormHelperText>
              </FormControl>
            )}
            rules={{
              min: {
                value: 0,
                message: 'Numărul trebuie să fie pozitiv',
              },
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name='polliniferous_potential'
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor={field.name} variant='outlined'>
                  Potențial polenifer:
                </InputLabel>
                <Select
                  {...field}
                  error={Boolean(fieldState.error)}
                  id={field.name}
                >
                  {(['L', 'M', 'H'] as const).map((choice) => (
                    <MenuItem key={choice} value={choice}>
                      {POLLINIFEROUS_POTENTIAL[choice]}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={Boolean(fieldState.error)}>
                  {fieldState.error?.message ?? ' '}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name='maximum_load'
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor={field.name} variant='outlined'>
                  Încărcare maximă recomandată:
                </InputLabel>
                <OutlinedInput
                  endAdornment={
                    <InputAdornment position='end'>stupi/ha</InputAdornment>
                  }
                  error={Boolean(fieldState.error)}
                  id={field.name}
                  type='number'
                  {...field}
                />
                <FormHelperText error={Boolean(fieldState.error)}>
                  {fieldState.error?.message ?? ' '}
                </FormHelperText>
              </FormControl>
            )}
            rules={{
              min: {
                value: 0,
                message: 'Numărul trebuie să fie pozitiv',
              },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6'>Caracteristici agronomice</Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name='soil_type'
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor={field.name} variant='outlined'>
                  Tip de sol:
                </InputLabel>
                <OutlinedInput
                  error={Boolean(fieldState.error)}
                  id={field.name}
                  {...field}
                />
                <FormHelperText error={Boolean(fieldState.error)}>
                  {fieldState.error?.message ?? ' '}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name='water_requirement'
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor={field.name} variant='outlined'>
                  Cerință de apă:
                </InputLabel>
                <Select
                  {...field}
                  error={Boolean(fieldState.error)}
                  id={field.name}
                >
                  {(['L', 'M', 'H'] as const).map((choice) => (
                    <MenuItem key={choice} value={choice}>
                      {WATER_REQUIREMENT[choice]}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={Boolean(fieldState.error)}>
                  {fieldState.error?.message ?? ' '}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name='other'
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor={field.name} variant='outlined'>
                  Altele:
                </InputLabel>
                <OutlinedInput
                  error={Boolean(fieldState.error)}
                  id={field.name}
                  multiline
                  rows={4}
                  {...field}
                />
                <FormHelperText error={Boolean(fieldState.error)}>
                  {fieldState.error?.message ?? ' '}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} textAlign='right'>
          <Button
            disabled={!formState.isValid || isLoading}
            type='submit'
            variant='text'
            sx={{ minWidth: 88 }}
          >
            {!isLoading ? 'Salvează' : <CircularProgress size={24.5} />}
          </Button>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={notify.open}
        autoHideDuration={6000}
        onClose={() => setNotify({ open: false, message: '' })}
      >
        <Alert
          onClose={() => setNotify({ open: false, message: '' })}
          severity={notify.severity ?? 'success'}
        >
          {notify.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

PlantDetailsForm.defaultProps = {
  commonName: '',
  latinName: '',
  onSuccess: undefined,
};
