import { useQueries } from '@tanstack/react-query';
import { fetchCropCategory } from '../services';

export function useCropCategoriesDetailsQuery(ids: number[]) {
  return useQueries({
    queries: ids.map((id) => ({
      queryKey: ['crop-category', id],
      queryFn: () => fetchCropCategory(id),
    })),
  });
}
