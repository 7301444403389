import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { Result } from '../types';

export function PlantResultCard({ result }: { result: Result }) {
  const { spacing } = useTheme();
  const [openTitleTooltip, setOpenTitleTooltip] = useState(false);

  return (
    <Card elevation={2}>
      <CardHeader
        bgcolor='grey.50'
        border={1}
        borderColor='grey.300'
        borderRadius={spacing(1, 1, 0, 0)}
        component={Box}
        title={
          <Stack alignItems='center' direction='row' gap={6} overflow='auto'>
            <Stack flexGrow={1} overflow='auto'>
              <Tooltip
                arrow
                onClose={() => setOpenTitleTooltip(false)}
                open={openTitleTooltip}
                PopperProps={{ disablePortal: true }}
                title={result.species?.scientificNameWithoutAuthor}
              >
                <Typography
                  color='primary.main'
                  fontSize={{ xs: 20, sm: 24 }}
                  fontStyle='italic'
                  fontWeight={700}
                  noWrap
                  onClick={() => setOpenTitleTooltip(!openTitleTooltip)}
                >
                  {result.species?.scientificNameWithoutAuthor ?? '-'}
                </Typography>
              </Tooltip>
              {result.species.commonNames.length > 0 && (
                <Typography>{result.species.commonNames.join(', ')}</Typography>
              )}
            </Stack>
            <Typography
              color='grey.600'
              display={{ xs: 'none', sm: 'inline-block' }}
              fontSize={{ xs: 16, sm: 20 }}
              fontWeight={700}
            >
              {result.species?.family?.scientificName ?? '-'}
            </Typography>
            <Typography fontSize={{ xs: 16, sm: 20 }} fontWeight={700}>
              {(result.score * 100).toFixed(2)}%
            </Typography>
          </Stack>
        }
      />
      <CardContent>
        <Stack direction='row' flexWrap='wrap' gap={3}>
          {result.images?.map(({ url, citation }) => (
            <Box
              alt={citation}
              borderRadius={1}
              component='img'
              key={url.s}
              maxWidth={120}
              src={url.s}
            />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
