// import { filter, find } from 'lodash';
// import { useCropCategoriesQuery, useHoneyFieldsQuery } from '../queries';
import { getDayOfYear } from 'date-fns';
import { filter, find } from 'lodash';
import { useEffect } from 'react';
import { useCropCategoriesQuery } from '../queries';
import {
  useClusterProvider,
  useHoneyFields,
  useIsHoneyFieldsHidden,
  useMap,
  useSelectedCropCategories,
  useSelectedDate,
  useSetIsUpdatingClusteringData,
} from '../store';
import { useBuildClusterLayer } from './useBuildClusterLayer';

export const useAddHoneyFieldMarkers = () => {
  const map = useMap();
  const buildClusterLayer = useBuildClusterLayer();
  const honeyFields = useHoneyFields();
  const clusterProvider = useClusterProvider();
  const { data: cropCategories } = useCropCategoriesQuery();

  useEffect(() => {
    if (map && !clusterProvider) {
      buildClusterLayer(honeyFields);
    }
  }, [buildClusterLayer, clusterProvider, honeyFields, map]);

  const selectedDate = useSelectedDate();
  const selectedCropCategories = useSelectedCropCategories();
  const isHoneyFieldsHidden = useIsHoneyFieldsHidden();
  const setIsUpdatingClusterData = useSetIsUpdatingClusteringData();

  useEffect(() => {
    if (map && clusterProvider) {
      const honeyFieldsByYear = filter(
        honeyFields,
        (field) =>
          field.reported_year ===
          (selectedDate?.getFullYear() ?? new Date().getFullYear()),
      );

      const cropCodesByDate = filter(cropCategories, (cropCategory) => {
        const bloomStart = new Date(cropCategory.blooming_period_start);
        const bloomEnd = new Date(cropCategory.blooming_period_end);

        return (
          !selectedDate ||
          (getDayOfYear(bloomStart) <= getDayOfYear(selectedDate) &&
            getDayOfYear(selectedDate) <= getDayOfYear(bloomEnd))
        );
      }).map((cropCategory) => cropCategory.crop_code);

      const cropCodes = filter(
        isHoneyFieldsHidden ? [] : selectedCropCategories,
        (code) => cropCodesByDate.includes(code),
      );
      const filteredHoneyFields = filter(honeyFieldsByYear, (field) =>
        cropCodes.includes(field.crop_category),
      );

      const dataPoints = filteredHoneyFields.map(
        (item) =>
          new H.clustering.DataPoint(
            parseFloat(item.lat),
            parseFloat(item.lng),
            1,
            {
              ...item,
              lifecycle:
                find(
                  cropCategories,
                  (cropCategory) =>
                    cropCategory.crop_code === item.crop_category,
                )?.lifecycle ?? 'A',
            },
          ),
      );

      setIsUpdatingClusterData(dataPoints.length > 0);
      clusterProvider.setDataPoints(dataPoints);
    }
  }, [
    clusterProvider,
    cropCategories,
    honeyFields,
    isHoneyFieldsHidden,
    map,
    selectedCropCategories,
    selectedDate,
    setIsUpdatingClusterData,
  ]);

  // useHoneyFieldsQuery(
  //   {},
  //   {
  //     enabled: Boolean(map) && isCropCategoriesFetched,
  //     onSuccess: (data) => {
  //       const currentDate = new Date(Date.now());
  //       const cropCodes = filter(cropCategories, (cropCategory) => {
  //         const bloomStart = new Date(cropCategory.blooming_period_start);
  //         const bloomEnd = new Date(cropCategory.blooming_period_end);

  //         return (
  //           !currentDate ||
  //           (bloomStart.getTime() <= currentDate.getTime() &&
  //             currentDate.getTime() <= bloomEnd.getTime())
  //         );
  //       }).map((cropCategory) => cropCategory.crop_code);

  //       const mappedData = data
  //         .filter((field) => cropCodes.includes(field.crop_category))
  //         .map((field) => ({
  //           ...field,
  //           lifecycle:
  //             find(
  //               cropCategories,
  //               (cropCategory) =>
  //                 cropCategory.crop_code === field.crop_category,
  //             )?.lifecycle ?? 'A',
  //         }));
  //       buildClusterLayer(mappedData);
  //     },
  //   },
  // );
};
