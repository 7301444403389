import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import fundingSrc from '../assets/img/funding_2480x1305.png';
import {
  BeeHiveDetailsPanel,
  Divider,
  Dropzone,
  FilterMenu,
  HoneyFieldDetailsPanel,
  IdentifyPlatResultsDialog,
  Map,
  MapCountySelectorOverlay,
  PlantMarkerIcon,
  Search,
  StatisticsContainer,
  TreeMarkerIcon,
} from '../components';
import {
  useAddHoneyFieldMarkers,
  useAddMapEvents,
  useShowBeeHiveAtPosition,
} from '../hooks';
import {
  useClearHiveData,
  useClearSelectedMarker,
  useClusterProvider,
  useFullscreen,
  useHiveData,
  useHoneyFields,
  useMap,
  useSelectedMarker,
} from '../store';

export function HomePage() {
  const { breakpoints, palette, zIndex } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  useAddHoneyFieldMarkers();
  useAddMapEvents();
  const selectedMarker = useSelectedMarker();
  const hiveData = useHiveData();

  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  const onScrollToMap = useCallback(() => {
    mapContainerRef.current?.scroll();
    mapContainerRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, []);

  const clearHiveData = useClearHiveData();
  const clearSelectedMarker = useClearSelectedMarker();
  const onCloseDialog = () => {
    clearHiveData();
    clearSelectedMarker();
  };

  const showBeeHivePosition = useShowBeeHiveAtPosition();
  const map = useMap();
  const clusterProvider = useClusterProvider();
  const [searchParams] = useSearchParams();
  const honeyFields = useHoneyFields();
  const showOverlay = useMemo(() => {
    if (honeyFields.length > 0) return false;
    const lat = searchParams.get('lat');
    const lng = searchParams.get('lng');
    return !lat || !lng;
  }, [honeyFields.length, searchParams]);
  useLayoutEffect(() => {
    if (!map || !clusterProvider) return;
    const lat = searchParams.get('lat');
    const lng = searchParams.get('lng');
    if (lat && lng) {
      onScrollToMap();
      showBeeHivePosition({ lat: Number(lat), lng: Number(lng) });
    }
  }, [clusterProvider, map, onScrollToMap, searchParams, showBeeHivePosition]);
  const fullscreen = useFullscreen();

  return (
    <>
      <Stack gap={4} py={5}>
        <Grid
          columnSpacing={{ xs: 0, md: 4 }}
          container
          rowSpacing={{ xs: 4, md: 0 }}
        >
          <Grid item xs={12} md>
            <Stack gap={4}>
              <Typography
                color='primary'
                fontSize={{ xs: 40, md: 52 }}
                textAlign='center'
                variant='h2'
              >
                Bine ai venit!
              </Typography>
              <Typography textAlign='center'>
                Aceasta este pagina de internet a proiectului{' '}
                <strong>
                  AGROAPIS- proiect pentru ridicarea valorii producției apicole
                  prin folosirea culturilor agricole benefice albinelor și
                  polenizatorilor în conformitate cu condițiile de agromediu
                </strong>
                . Proiectul este finanțat prin P.N.D.R. submăsura 16.1- Sprijin
                pentru înființarea și funcționarea grupurilor operaționale (GO),
                pentru dezvoltarea de proiecte pilot, noi produse
              </Typography>
              <Box textAlign='center'>
                <Button onClick={onScrollToMap} variant='contained'>
                  Vezi hartă
                </Button>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md>
            <Box
              borderRadius={2}
              boxShadow={3}
              height={{ xs: 270, sm: 400 }}
              sx={{
                backgroundImage: `url(${fundingSrc})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
              }}
              width='100%'
            />
          </Grid>
        </Grid>
        <Divider />
        <Typography textAlign='center' variant='h5'>
          Găsește cea mai apropiată cultură meliferă
        </Typography>
        <Container maxWidth='md'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Search />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                gap: 2,
              }}
            >
              <FilterMenu />
            </Grid>
            <Grid item>
              <Stack alignItems='center' direction='row' gap={3}>
                <Typography fontWeight='bold' variant='body2'>
                  Legendă:
                </Typography>
                <Stack alignItems='center' direction='row'>
                  <PlantMarkerIcon sx={{ color: '#F99771' }} />
                  <Typography variant='body2'>Plante anuale</Typography>
                </Stack>
                <Stack alignItems='center' direction='row'>
                  <TreeMarkerIcon sx={{ color: '#0BC58B' }} />
                  <Typography variant='body2'>
                    Plante multi-anuale/Arbori
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Container>

        <Stack
          ref={mapContainerRef}
          direction='row'
          gap={fullscreen ? 0 : 3}
          position={fullscreen ? 'fixed' : 'relative'}
          sx={
            fullscreen
              ? {
                  top: 0,
                  left: 0,
                  height: '100vh',
                  width: 1,
                  zIndex: 2000,
                }
              : {}
          }
        >
          {showOverlay && <MapCountySelectorOverlay />}
          <Map height={fullscreen ? '100%' : 560} />
          {/* <Collapse
            in={
              Boolean(selectedMarker) ||
              (hiveData?.honeyFieldsInRange?.length ?? 0) > 0
            }
            mountOnEnter
            orientation='horizontal'
            timeout='auto'
            unmountOnExit
          > */}
          {(selectedMarker || hiveData) && !isMobile && (
            <Box
              bgcolor={palette.background.default}
              bottom={0}
              left={0}
              maxHeight={fullscreen ? '100%' : 560}
              maxWidth={400}
              position={{ xs: 'absolute', sm: 'relative' }}
              right={0}
              top={0}
              zIndex={{ xs: zIndex.appBar - 1, sm: 0 }}
            >
              {selectedMarker && <HoneyFieldDetailsPanel />}
              {hiveData && <BeeHiveDetailsPanel />}
            </Box>
          )}
          {/* </Collapse> */}
        </Stack>

        <Typography textAlign='center' pb={4} pt={2}>
          Nu uita să verifici aplicația{' '}
          <Link href='http://apisana.polenizare.ro/' target='_blank'>
            Apisana
          </Link>{' '}
          pentru a vedea alertele fitosanitare active din zonă!
        </Typography>

        <StatisticsContainer />

        <Stack alignItems='center' gap={4} py={12}>
          <Typography
            fontWeight={700}
            textAlign='center'
            variant='h5'
            fontSize={{ xs: 20, sm: 24 }}
          >
            Nu știi cum se numește o plantă? Adaugă între una și cinci
            fotografii aici și te putem ajuta
          </Typography>
          <Dropzone />
        </Stack>
      </Stack>

      <IdentifyPlatResultsDialog />

      {/* <FullscreenMap /> */}

      <Dialog open={isMobile && !!hiveData} fullWidth onClose={onCloseDialog}>
        <DialogContent sx={{ p: 0 }}>
          <BeeHiveDetailsPanel />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseDialog} variant='outlined'>
            Închide
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isMobile && !!selectedMarker}
        fullWidth
        onClose={onCloseDialog}
      >
        <DialogContent sx={{ p: 0 }}>
          <HoneyFieldDetailsPanel />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseDialog} variant='outlined'>
            Închide
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
