import {
  Alert,
  Autocomplete,
  AutocompleteChangeReason,
  Snackbar,
  TextField,
  Typography,
  UseAutocompleteProps,
} from '@mui/material';
import { debounce, first } from 'lodash';
import { KeyboardEventHandler, useCallback, useState } from 'react';
import { useShowBeeHiveAtPosition } from '../hooks';
import {
  useGeocodeByAddressQuery,
  useHoneyFieldsQuery,
  useMapAutocompleteResultsQuery,
} from '../queries';
import { useSelectedDate, useUpdateHoneyFields } from '../store';
import { MapDiscoverResult } from '../types';
import { getCountyId, isMapDiscoverResult } from '../utils';

const DEFAULT_OPTION: Pick<MapDiscoverResult, 'title'> = {
  title: 'Folosește locația curentă',
};

export function Search() {
  const [value, setValue] = useState<
    { title: string } | MapDiscoverResult | null
  >(null);

  const [options, setOptions] = useState<
    ({ title: string } | MapDiscoverResult)[]
  >([DEFAULT_OPTION]);

  const [searchAddress, setSearchAddress] = useState('');
  const [selectedAddress, setSelectedAddress] = useState('');
  const [openMessage, setOpenMessage] = useState(false);

  const showBeeHivePosition = useShowBeeHiveAtPosition();

  useMapAutocompleteResultsQuery(searchAddress, {
    onSuccess: ({ items }) => setOptions([DEFAULT_OPTION, ...items]),
    enabled: searchAddress !== DEFAULT_OPTION.title,
  });
  const selectedDate = useSelectedDate();

  const updateHoneyFields = useUpdateHoneyFields();
  const [county, setCounty] = useState<string>('');
  useHoneyFieldsQuery(
    { county, reportedYear: selectedDate?.getFullYear().toString() },
    {
      enabled: Boolean(county),
      onSuccess: (data) => {
        updateHoneyFields(data);
      },
    },
  );

  useGeocodeByAddressQuery(selectedAddress, {
    onSuccess: ({ items }) => {
      const firstResult = first(items);
      if (firstResult) {
        const { lat, lng } = firstResult.position;
        showBeeHivePosition({ lat, lng });
      }
    },
    enabled: Boolean(selectedAddress),
  });

  const onInputChangeHandler = useCallback<
    Required<
      UseAutocompleteProps<
        { title: string } | MapDiscoverResult,
        false,
        false,
        false
      >
    >['onInputChange']
  >((_, newInputValue) => setSearchAddress(newInputValue), []);

  const debouncedOnInputChangeHandler = debounce(onInputChangeHandler, 400);

  const onOptionChangeHandler = useCallback<
    Required<
      UseAutocompleteProps<
        { title: string } | MapDiscoverResult,
        false,
        false,
        false
      >
    >['onChange']
  >(
    (
      _,
      newValue: MapDiscoverResult | Pick<MapDiscoverResult, 'title'> | null,
      reason: AutocompleteChangeReason,
    ) => {
      if (reason === 'clear') {
        setValue(null);
        return;
      }

      setValue(newValue);
      if (isMapDiscoverResult(newValue)) {
        setSelectedAddress(newValue.title);
        const countyId = getCountyId(newValue.address.county);
        if (countyId) {
          setCounty(countyId);
        }
      } else {
        navigator.geolocation.getCurrentPosition(
          ({ coords }) => {
            const { latitude: lat, longitude: lng } = coords;
            showBeeHivePosition({ lat, lng });
          },
          () => setOpenMessage(true),
        );
      }
    },
    [showBeeHivePosition],
  );

  const onEnterSearch = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === 'Enter') {
        const [latString, lngString] = event.currentTarget.value.split(',');
        try {
          const lat = parseFloat(latString);
          const lng = parseFloat(lngString);
          if (lat && lng) {
            showBeeHivePosition({ lat, lng });
            setValue({ title: event.currentTarget.value });
          }
        } catch {
          console.error('Invalid lat and lng');
        }
      }
    },
    [showBeeHivePosition],
  );

  return (
    <>
      <Autocomplete
        disablePortal
        filterOptions={(x) => x}
        fullWidth
        getOptionLabel={(option) => {
          if (isMapDiscoverResult(option)) {
            return option.address.label;
          }
          return option.title;
        }}
        onInputChange={debouncedOnInputChangeHandler}
        options={options}
        onChange={onOptionChangeHandler}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Unde ești acum?'
            InputProps={{
              ...params.InputProps,
              notched: false,
              onKeyDown: onEnterSearch,
            }}
          />
        )}
        renderOption={(props, option) => (
          <li
            {...props}
            key={isMapDiscoverResult(option) ? option.id : option.title}
          >
            <Typography>
              {isMapDiscoverResult(option)
                ? option.address.label
                : option.title}
            </Typography>
          </li>
        )}
        value={value}
      />
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={6000}
        onClose={() => setOpenMessage(false)}
        open={openMessage}
      >
        <Alert severity='warning' sx={{ width: '100%' }}>
          Locația este blocată. Pentru a folosi această opțiune permite
          folosirea locației și încearcă din nou.
        </Alert>
      </Snackbar>
    </>
  );
}
