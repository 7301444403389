export const plantMapMarkerSvg = `<svg width="28" height="40" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 12.25 7 20 7 20C7 20 0 12.25 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025ZM4.87868 9.45469C4.31607 8.89209 4 8.12902 4 7.33337C5.65667 7.33337 7 8.66671 7 10.3334C6.20435 10.3334 5.44129 10.0173 4.87868 9.45469ZM7 10.3334C7 8.66671 8.33333 7.33337 10 7.33337C10 8.12902 9.68393 8.89209 9.12132 9.45469C8.55871 10.0173 7.79565 10.3334 7 10.3334ZM7.58926 5.07745C7.43298 4.92117 7.22101 4.83337 7 4.83337C6.77899 4.83337 6.56702 4.92117 6.41074 5.07745C6.25446 5.23373 6.16667 5.44569 6.16667 5.66671C6.16667 5.88772 6.25446 6.09968 6.41074 6.25596C6.56702 6.41224 6.77899 6.50004 7 6.50004C7.22101 6.50004 7.43298 6.41224 7.58926 6.25596C7.74554 6.09968 7.83333 5.88772 7.83333 5.66671C7.83333 5.44569 7.74554 5.23373 7.58926 5.07745ZM5.11074 7.00596C4.95446 6.84968 4.86667 6.63772 4.86667 6.41671C4.86667 6.08337 5.06333 5.80004 5.34333 5.66671C5.06333 5.53337 4.86667 5.24671 4.86667 4.91671C4.86667 4.69569 4.95446 4.48373 5.11074 4.32745C5.26702 4.17117 5.47899 4.08337 5.7 4.08337C5.87667 4.08337 6.04 4.13671 6.16667 4.23004V4.16671C6.16667 3.94569 6.25446 3.73373 6.41074 3.57745C6.56702 3.42117 6.77899 3.33337 7 3.33337C7.22101 3.33337 7.43298 3.42117 7.58926 3.57745C7.74554 3.73373 7.83333 3.94569 7.83333 4.16671V4.23004C7.96 4.13671 8.12333 4.08337 8.3 4.08337C8.76 4.08337 9.13333 4.45671 9.13333 4.91671C9.13333 5.24671 8.93667 5.53337 8.65667 5.66671C8.93667 5.80004 9.13333 6.08337 9.13333 6.41671C9.13333 6.87671 8.76 7.25004 8.3 7.25004C8.12333 7.25004 7.96 7.19337 7.83333 7.10337V7.16671C7.83333 7.38772 7.74554 7.59968 7.58926 7.75596C7.43298 7.91224 7.22101 8.00004 7 8.00004C6.77899 8.00004 6.56702 7.91224 6.41074 7.75596C6.25446 7.59968 6.16667 7.38772 6.16667 7.16671V7.10337C6.04 7.19337 5.87667 7.25004 5.7 7.25004C5.47899 7.25004 5.26702 7.16224 5.11074 7.00596Z" fill="currentColor"/>
<path d="M4 7.32996C4 8.12561 4.31607 8.88867 4.87868 9.45128C5.44129 10.0139 6.20435 10.33 7 10.33C7 8.66329 5.65667 7.32996 4 7.32996ZM7 4.82996C7.22101 4.82996 7.43298 4.91775 7.58926 5.07403C7.74554 5.23031 7.83333 5.44228 7.83333 5.66329C7.83333 5.8843 7.74554 6.09626 7.58926 6.25255C7.43298 6.40883 7.22101 6.49662 7 6.49662C6.77899 6.49662 6.56702 6.40883 6.41074 6.25255C6.25446 6.09626 6.16667 5.8843 6.16667 5.66329C6.16667 5.44228 6.25446 5.23031 6.41074 5.07403C6.56702 4.91775 6.77899 4.82996 7 4.82996ZM4.86667 6.41329C4.86667 6.6343 4.95446 6.84626 5.11074 7.00255C5.26702 7.15883 5.47899 7.24662 5.7 7.24662C5.87667 7.24662 6.04 7.18996 6.16667 7.09996C6.16667 7.11996 6.16667 7.13996 6.16667 7.16329C6.16667 7.3843 6.25446 7.59626 6.41074 7.75255C6.56702 7.90883 6.77899 7.99662 7 7.99662C7.22101 7.99662 7.43298 7.90883 7.58926 7.75255C7.74554 7.59626 7.83333 7.3843 7.83333 7.16329C7.83333 7.13996 7.83333 7.11996 7.83333 7.09996C7.96 7.18996 8.12333 7.24662 8.3 7.24662C8.76 7.24662 9.13333 6.87329 9.13333 6.41329C9.13333 6.07996 8.93667 5.79662 8.65667 5.66329C8.93667 5.52996 9.13333 5.24329 9.13333 4.91329C9.13333 4.45329 8.76 4.07996 8.3 4.07996C8.12333 4.07996 7.96 4.13329 7.83333 4.22662C7.83333 4.20662 7.83333 4.18329 7.83333 4.16329C7.83333 3.94228 7.74554 3.73031 7.58926 3.57403C7.43298 3.41775 7.22101 3.32996 7 3.32996C6.77899 3.32996 6.56702 3.41775 6.41074 3.57403C6.25446 3.73031 6.16667 3.94228 6.16667 4.16329C6.16667 4.18329 6.16667 4.20662 6.16667 4.22662C6.04 4.13329 5.87667 4.07996 5.7 4.07996C5.47899 4.07996 5.26702 4.16775 5.11074 4.32403C4.95446 4.48031 4.86667 4.69228 4.86667 4.91329C4.86667 5.24329 5.06333 5.52996 5.34333 5.66329C5.06333 5.79662 4.86667 6.07996 4.86667 6.41329ZM7 10.33C7.79565 10.33 8.55871 10.0139 9.12132 9.45128C9.68393 8.88867 10 8.12561 10 7.32996C8.33333 7.32996 7 8.66329 7 10.33Z" fill="white"/>
</svg>`;

export const treeMapMakerSvg = `<svg width="28" height="40" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0Z" fill="currentColor"/>
<path d="M6.4 10.7V8.996C6.212 9.064 6.012 9.1 5.8 9.1C4.8 9.1 4 8.3 4 7.3C4 6.792 4.2 6.336 4.544 6.008C4.452 5.792 4.4 5.552 4.4 5.3C4.4 4.3 5.2 3.5 6.2 3.5C6.824 3.5 7.376 3.82 7.7 4.3C7.732 4.3 7.764 4.3 7.8 4.3C8.08891 4.3 8.37499 4.3569 8.6419 4.46746C8.90882 4.57803 9.15135 4.74008 9.35564 4.94437C9.55992 5.14865 9.72197 5.39118 9.83253 5.6581C9.9431 5.92501 10 6.21109 10 6.5C10 6.78891 9.9431 7.07499 9.83253 7.3419C9.72197 7.60882 9.55992 7.85135 9.35564 8.05563C9.15135 8.25992 8.90882 8.42197 8.6419 8.53253C8.37499 8.64309 8.08891 8.7 7.8 8.7C7.6 8.7 7.4 8.672 7.2 8.616V10.7H6.4Z" fill="white"/>
</svg>`;

export const hiveMapMarkerSvg = `<svg width="28" height="40" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 12.25 7 20 7 20C7 20 0 12.25 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025ZM11 4.77778V3H3V4.77778H11ZM10.5556 11V5.22222H3.44444V11H10.5556ZM5.73175 9.73175C5.77343 9.69008 5.82995 9.66667 5.88889 9.66667H8.11111C8.17005 9.66667 8.22657 9.69008 8.26825 9.73175C8.30992 9.77343 8.33333 9.82995 8.33333 9.88889V10.5556H5.66667V9.88889C5.66667 9.82995 5.69008 9.77343 5.73175 9.73175Z" fill="currentColor"/>
</svg>`;

export const createMapIcon = (
  element: string,
  style: Pick<CSSStyleDeclaration, 'color' | 'marginLeft' | 'marginTop'>,
): H.map.DomIcon => {
  const iconContainer = document.createElement('div');
  iconContainer.style.color = style.color;
  iconContainer.style.marginLeft = style.marginLeft;
  iconContainer.style.marginTop = style.marginTop;
  iconContainer.style.cursor = 'pointer';
  iconContainer.innerHTML = element;

  return new H.map.DomIcon(iconContainer);
};

export const createDomMarkerAtPosition = (
  position: H.geo.IPoint | H.geo.MultiPoint,
  options: H.map.DomMarker.Options,
): H.map.DomMarker => new H.map.DomMarker(position, options);

export const createCircleAtPosition = (
  center: H.geo.IPoint,
  radius: number,
  options?: H.map.Circle.Options,
): H.map.Circle => new H.map.Circle(center, radius, options);
