import { alpha, createTheme } from '@mui/material/styles';
import { DIVIDER, PRIMARY, WHITE } from '../constants';

const basicTheme = createTheme({
  palette: {
    error: {
      main: '#db0000',
    },
    primary: {
      main: PRIMARY,
      contrastText: WHITE,
    },
    divider: DIVIDER,
  },
  spacing: 4,
  typography: {
    fontFamily: 'Inter',
  },
});

export const theme = createTheme(basicTheme, {
  components: {
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            padding: 0,
          },

          '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: 12,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          textTransform: 'none',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        content: {
          overflow: 'auto',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        'html, body, #root': {
          height: '100%',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          gap: basicTheme.spacing(1),
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: 0,
          color: basicTheme.palette.text.primary,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          color: basicTheme.palette.text.primary,
          fontWeight: basicTheme.typography.fontWeightBold,
          position: 'relative',
          transform: 'none',

          '&.Mui-focused': {
            color: basicTheme.palette.text.primary,
          },
        },
        root: {
          '&.Mui-error': {
            color: 'inherit',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderBottomColor: alpha(basicTheme.palette.common.black, 0.07),
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderBottomColor: alpha(basicTheme.palette.common.black, 0.07),
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: basicTheme.spacing(3, 3),
        },
        multiline: {
          padding: 0,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          '&[data-popper-placement*="bottom"] > .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom':
            {
              marginTop: basicTheme.spacing(1),
            },
        },
      },
    },
  },
  palette: {
    secondary: {
      main: alpha(basicTheme.palette.common.black, 0.7),
    },
  },
});
