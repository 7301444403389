import {
  Box,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import logoAisr from '../assets/img/logo_aisr.jpg';
import logoRomapisSrc from '../assets/img/logo_romapis.png';
import logoUsamv from '../assets/img/logo_usamv.jpg';
import { Divider } from '../components';

export function AboutPage() {
  return (
    <Stack gap={4} py={5}>
      <Typography color='primary' fontSize={{ xs: 40, md: 52 }} variant='h2'>
        Despre proiect
      </Typography>
      <Typography>
        Proiectul AGROAPIS a fost conceput în cadrul federației ROMAPIS în anul
        2017, a fost evaluat și aprobat de AFIR în faza de exprimare a intenției
        în 2018, a fost înaintată documentația în faza a doua de evaluare în
        2019 și s-a calificat pentru finanțare în anul 2020 iar contractul de
        finanțare a fost semnat la 31.05.2021.
      </Typography>
      <Typography>
        Proiectul își propune identificarea, studierea şi punerea în practică a
        surselor alternative de hrană (nectar şi polen) pentru albine şi alte
        insecte polenizatoare prin introducerea în circuitul agricol a unor
        culturi care să contribuie la îmbunătățirea condițiilor de agromediu în
        contextul limitării surselor de hrană pentru acestea datorată extinderii
        monoculturilor şi a culturilor având calităţi melifere diminuate şi cu
        perioada de înflorire mai scurtă.
      </Typography>
      <Typography>
        Se urmărește în special posibilitatea valorificării din punct de vedere
        apicol a culturilor agricole de acoperire. În acest sens se testează
        specii folosite în cultura a 2-a, care are rolul principal de a acoperi
        terenurile agricole rămase libere după recoltarea culturilor timpurii
        (precum cerealele păioase de toamnă) astfel încât pe perioada de vară să
        se evite îmburuienarea, pierderea de umiditate din sol, eroziunea iar în
        final să se obțină îmbogățirea solului în elemente nutritive prin
        descompunerea masei vegetale rezultate din desființarea acestor culturi
        și înglobarea în sol.
      </Typography>

      <Stack direction='column' gap={8} justifyContent='center'>
        <Box>
          <Typography fontWeight='bold' gutterBottom>
            Proiect realizat de
          </Typography>
          <Box>
            <Link href='https://www.romapis.org/' target='_blank'>
              <img src={logoRomapisSrc} alt='ROMAPIS' />
            </Link>
          </Box>
        </Box>
        <Box>
          <Typography fontWeight='bold' gutterBottom>
            Parteneri proiect
          </Typography>
          <Stack direction='column' gap={1}>
            <Box>
              <Typography>- USAMV București</Typography>
              <Link href='https://www.usamv.ro/index.php/ro/' target='_blank'>
                <img src={logoUsamv} alt='USAMV' height={100} />
              </Link>
            </Box>
            <Box>
              <Typography>
                - AISR: Alianța Industriei Semințelor din România
              </Typography>
              <Link href='https://aliantasemintelor.ro/' target='_blank'>
                <img src={logoAisr} alt='AISR' height={80} />
              </Link>
            </Box>

            <Typography>- Apicultor Benchea Gabriel</Typography>
            <Typography>- Apicultor Pință Gheorghe</Typography>
            <Typography>- Apicultor Tănăsoiu Ionuț</Typography>
            <Typography>- Ferma ecologică Eco Fruct SRL</Typography>
            <Typography>- Ferma POLIROM PROD SRL</Typography>
          </Stack>
        </Box>

        <Box>
          <Typography fontWeight='bold' gutterBottom>
            Contractanți principali
          </Typography>

          <Typography>- Blankspace Data SRL</Typography>
        </Box>
      </Stack>

      <Divider />

      <Typography
        color='primary'
        fontSize={{ xs: 36, md: 48 }}
        textAlign='center'
        variant='h2'
      >
        Implementarea proiectului
      </Typography>

      <Typography>
        Proiectul a fost conceput pentru a se desfășura pe o perioadă de 36 de
        luni dar datorită perioadei scurte rămase între momentul demarării
        finanțării și sfârșitul finanțării datorată regulilor stricte ale PNDR,
        în final perioada de implementare s-a redus practic la 25 de luni
        acoperind aproximativ 3 sezoane agricole pentru testare.
      </Typography>
      <Typography>
        Rezultatele și concluziile proiectului AGROAPIS vor fi diseminate la
        terminarea cercetării și puse la dispoziția celor interesați. Aplicația
        IT de față, dezvoltată în cadrul proiectului pune la dispoziția
        apicultorilor și a fermierilor
      </Typography>
      <List dense>
        <ListItem>
          <ListItemText primary='- informații despre culturile de acoperire cu potențial melifer (caracteristici agricole și melifere, tehnologii agricole, etc),' />
        </ListItem>
        <ListItem>
          <ListItemText primary='- o platformă pentru punerea în legătură a celor 2 categorii de utilizatori' />
        </ListItem>
        <ListItem>
          <ListItemText primary='- instrumente online de recunoaștere a speciilor melifere studiate în proiect cu posibilitatea de extindere și la alte plante melifere și' />
        </ListItem>
        <ListItem>
          <ListItemText primary='- un instrument online pentru găsirea și evaluarea de către apicultori a culturilor agricole de interes apicol.' />
        </ListItem>
      </List>
    </Stack>
  );
}
