import { Box, Grid, Stack, Typography } from '@mui/material';
import { chain, groupBy, upperFirst } from 'lodash';
import numeral from 'numeral';
import { useMemo } from 'react';
import { CARD_GREEN, CARD_ORANGE, CARD_YELLOW } from '../constants';
import { useCropCategoriesQuery, useStatsQuery } from '../queries';
import { useSelectedDate } from '../store';
import { CropIcon, TractorIcon, BeeHiveIcon } from './Icons';
import { StatisticsCard } from './StatisticsCard';

export function StatisticsContainer() {
  const { data: stats, isInitialLoading: isLoadingStats } = useStatsQuery();
  const { data: cropCategories } = useCropCategoriesQuery();
  const selectedDate = useSelectedDate();

  const selectedYear = useMemo(
    () => selectedDate?.getFullYear() ?? new Date().getFullYear(),
    [selectedDate],
  );

  const groupedCategories = useMemo(
    () => groupBy(cropCategories, 'crop_code'),
    [cropCategories],
  );

  const cropCategoriesStats = useMemo(() => {
    if (!cropCategories) {
      return [];
    }

    return chain(stats?.crop_categories[selectedYear])
      .toPairs()
      .filter(([_, value]) => value.melliferous_potential > 0)
      .filter(([key]) => Boolean(groupedCategories[key]))
      .map(([key, value]) => ({
        ...groupedCategories[key][0],
        ...value,
      }))
      .orderBy('melliferous_potential', 'desc')
      .value();
  }, [cropCategories, stats?.crop_categories, selectedYear, groupedCategories]);

  return (
    <>
      <Stack direction={{ xs: 'column', sm: 'row' }} gap={{ xs: 5, md: 8 }}>
        <StatisticsCard
          color={CARD_GREEN}
          count={stats?.honey_fields_yearly[selectedYear] ?? 0}
          icon={CropIcon}
          isLoading={isLoadingStats}
          label='culturi înregistrate*'
        />
        <StatisticsCard
          color={CARD_YELLOW}
          count={Math.ceil(stats?.hectares_yearly[selectedYear] ?? 0)}
          icon={TractorIcon}
          isLoading={isLoadingStats}
          label='total hectare culturi melifere*'
          unit='ha'
        />
        <StatisticsCard
          color={CARD_ORANGE}
          count={Math.ceil(
            stats?.melliferous_potential_yearly[selectedYear] ?? 0,
          )}
          icon={BeeHiveIcon}
          isLoading={isLoadingStats}
          label='total potențial melifer*'
          unit='kg'
        />
      </Stack>

      <Typography textAlign='center'>
        *Aceste valori au fost calculate pentru intreg teritoriul Romaniei
        pentru anul {selectedYear}
      </Typography>

      <Grid
        container
        spacing={4}
        maxHeight={400}
        overflow='auto'
        pb={4}
        justifyContent='space-between'
      >
        {cropCategoriesStats.map((crop) => (
          <Grid item key={crop.crop_code} xs={12} sm={6} lg={4}>
            <Box boxShadow={2} borderRadius={2} px={4} py={3}>
              <Typography fontWeight={700} mb={2}>
                {upperFirst(crop.name.toLowerCase())}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md='auto'>
                  <Typography fontWeight={700} variant='body2'>
                    {crop.fields}
                  </Typography>
                  <Typography variant='body2' fontSize='0.75rem'>
                    culturi înregistrate*
                  </Typography>
                </Grid>
                <Grid item xs={12} md='auto'>
                  <Typography fontWeight={700} variant='body2'>
                    {numeral(crop.hectares).format(
                      crop.hectares >= 1 ? '' : '0.0',
                    )}
                    <Typography component='span' fontSize='0.75rem'>
                      ha
                    </Typography>
                  </Typography>
                  <Typography variant='body2' fontSize='0.75rem'>
                    total hectare*
                  </Typography>
                </Grid>
                <Grid item xs={12} md='auto'>
                  <Typography fontWeight={700} variant='body2'>
                    {numeral(crop.melliferous_potential).format()}
                    <Typography component='span' fontSize='0.75rem'>
                      kg
                    </Typography>
                  </Typography>
                  <Typography variant='body2' fontSize='0.75rem'>
                    total potențial melifer*
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
