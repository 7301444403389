import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchAssociativeForms } from '../services';
import { AssociativeForm } from '../types';

export function useAssociativeFormsQuery(
  options?: UseQueryOptions<
    AssociativeForm[],
    Error,
    AssociativeForm[],
    string[]
  >,
) {
  const enabled = options?.enabled ?? true;

  return useQuery(['associative-forms'], fetchAssociativeForms, {
    ...options,
    enabled,
  });
}
