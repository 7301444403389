import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchStats } from '../services';
import { Stats } from '../types';

export function useStatsQuery(
  options?: UseQueryOptions<Stats, unknown, Stats>,
) {
  return useQuery({
    queryKey: ['stats'],
    queryFn: fetchStats,
    ...options,
  });
}
