import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { uniqueId } from 'lodash';
import { useMemo, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useCropCategoriesQuery, useCropCategoryQuery } from '../queries';
import {
  useCloseIdentifyPlantResultsDialog,
  useIdentifyPlantResults,
  useIsOpenIdentifyPlantResultsDialog,
} from '../store';
import { PlantDetailsForm } from './PlantDetailsForm';
import { PlantResultCard } from './PlantResultCard';

export function IdentifyPlatResultsDialog() {
  const isOpen = useIsOpenIdentifyPlantResultsDialog();
  const closeDialog = useCloseIdentifyPlantResultsDialog();
  const data = useIdentifyPlantResults();

  const { data: cropCategories } = useCropCategoriesQuery();

  const [openTitleTooltip, setOpenTitleTooltip] = useState(false);

  const [bestMatch, ...otherMatches] = data?.results ?? [];

  const cropCategory = useMemo(
    () =>
      cropCategories?.find(
        (c) =>
          c.latin_name.toLowerCase() ===
            bestMatch?.species.scientificNameWithoutAuthor.toLowerCase() ||
          c.botanical_family?.toLowerCase() ===
            bestMatch?.species.genus.scientificNameWithoutAuthor.toLowerCase(),
      ),
    [
      bestMatch?.species.genus.scientificNameWithoutAuthor,
      bestMatch?.species.scientificNameWithoutAuthor,
      cropCategories,
    ],
  );
  const { data: cropDetails, isInitialLoading: isLoadingCropDetails } =
    useCropCategoryQuery(cropCategory?.crop_code);

  return (
    <Dialog
      fullWidth
      keepMounted={false}
      maxWidth='md'
      onClose={closeDialog}
      open={isOpen}
    >
      <DialogTitle
        alignItems='center'
        borderBottom={1}
        borderColor='grey.300'
        component={Stack}
        direction='row'
        gap={6}
      >
        {bestMatch && (
          <>
            <Stack flexGrow={1} overflow='auto'>
              <Tooltip
                arrow
                onClose={() => setOpenTitleTooltip(false)}
                open={openTitleTooltip}
                title={bestMatch.species.scientificNameWithoutAuthor}
              >
                <Typography
                  color='primary.main'
                  fontSize={{ xs: 24, sm: 28 }}
                  fontStyle='italic'
                  fontWeight={700}
                  noWrap
                  onClick={() => setOpenTitleTooltip(!openTitleTooltip)}
                >
                  {bestMatch.species.scientificNameWithoutAuthor}
                </Typography>
              </Tooltip>
              {bestMatch.species.commonNames.length > 0 && (
                <Typography>
                  {bestMatch.species.commonNames.join(', ')}
                </Typography>
              )}
            </Stack>
            <Typography
              color='grey.600'
              display={{ xs: 'none', sm: 'inline-block' }}
              fontSize={{ xs: 16, sm: 20 }}
              fontWeight={700}
            >
              {bestMatch.species.genus.scientificName}
            </Typography>
            <Typography fontSize={{ xs: 16, sm: 20 }} fontWeight={700}>
              {(bestMatch.score * 100).toFixed(2)}%
            </Typography>
          </>
        )}
        <IconButton onClick={closeDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isLoadingCropDetails && (
          <Box
            alignItems='center'
            display='flex'
            justifyContent='center'
            height={80}
          >
            <CircularProgress size={30} />
          </Box>
        )}
        {!cropDetails && !isLoadingCropDetails && (
          <>
            <Alert severity='info' sx={{ my: 4 }}>
              <AlertTitle>
                Ne pare rău, nu am găsit informații despre caracteristicile
                apicole/agricole pentru această plantă.
              </AlertTitle>
              <Typography>
                Aveți informații despre această plantă? Ajutați-ne completând
                formularul următor.
              </Typography>
            </Alert>

            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_CAPTCHA_API_KEY ?? ''}
              language='ro'
            >
              <PlantDetailsForm
                commonName={bestMatch?.species?.commonNames?.join(', ')}
                latinName={bestMatch?.species?.genus?.scientificName}
                onSuccess={closeDialog}
              />
            </GoogleReCaptchaProvider>
          </>
        )}
        {cropDetails && !isLoadingCropDetails && (
          <>
            <Grid container rowSpacing={4} pt={4} pb={2}>
              <Grid container item rowSpacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight='bold' variant='h6'>
                    Caracteristici apicole
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontWeight='bold'>
                    Perioada aproximativă de înflorire
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography>
                    {cropDetails?.blooming_period_start} -{' '}
                    {cropDetails?.blooming_period_end}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontWeight='bold'>Potențial melifer</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography>
                    {cropDetails?.melliferous_potential ?? '-'} kg/ha
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontWeight='bold'>Potențial polenifer</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography>
                    {cropDetails?.polliniferous_potential ?? '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontWeight='bold'>
                    Încărcare maximă recomandată
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography>{cropDetails?.maximum_load ?? '-'}</Typography>
                </Grid>
              </Grid>

              <Grid container item>
                <Grid item xs={12}>
                  <Typography fontWeight='bold' variant='h6'>
                    Caracteristici agronomice
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontWeight='bold'>Tip de sol</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography>{cropDetails?.soil_type ?? '-'}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontWeight='bold'>Cerință de apă</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography>
                    {cropDetails?.water_requirement ?? '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontWeight='bold'>Altele</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography>{cropDetails?.other ?? '-'}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Stack direction='row' flexWrap='wrap' gap={2} pt={2} pb={4}>
              {bestMatch?.images.map(({ url, citation }) => (
                <Box
                  alt={citation}
                  borderRadius={1}
                  component='img'
                  key={url.m}
                  maxWidth={200}
                  src={url.m}
                />
              ))}
            </Stack>

            <Divider />
          </>
        )}

        {otherMatches && (
          <Stack gap={4} py={4}>
            <Typography fontSize={20} fontWeight='bold'>
              Alte rezultate ({otherMatches.length})
            </Typography>

            {otherMatches.map((result) => (
              <PlantResultCard key={uniqueId()} result={result} />
            ))}
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}
