import { BEES_RADIUS, BEE_HIVE_KEY, BEE_HIVE_MARKER_KEY } from '../constants';
import {
  createCircleAtPosition,
  createDomMarkerAtPosition,
  createMapIcon,
  hiveMapMarkerSvg,
} from './mapObjectsUtils';

export const createBeeHiveAtPosition = (
  position: H.geo.IPoint,
  onMarkerTap?: () => void,
): H.map.Group => {
  const icon = createMapIcon(hiveMapMarkerSvg, {
    color: '#F99771',
    marginLeft: '-14px',
    marginTop: '-40px',
  });

  const marker = createDomMarkerAtPosition(position, {
    icon,
    data: { key: BEE_HIVE_MARKER_KEY },
  });

  marker.addEventListener('tap', (event: H.mapevents.Event) => {
    event.stopPropagation();
    onMarkerTap?.();
  });

  const circle = createCircleAtPosition(position, BEES_RADIUS);

  const group = new H.map.Group({
    objects: [marker, circle],
    data: { key: BEE_HIVE_KEY },
  });

  return group;
};
