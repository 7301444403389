import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchAddressByGeocode } from '../services';
import { MapDiscoverResult, MapDiscoverResults } from '../types';

export const useHoneyFieldNearestAddressQuery = (
  { lat, lng }: { lat: number; lng: number },
  options?: UseQueryOptions<
    MapDiscoverResults,
    Error,
    MapDiscoverResult,
    (string | number)[]
  >,
) => {
  const enabled = Boolean(lat) && Boolean(lng) && (options?.enabled ?? true);

  return useQuery(
    ['honey-field-nearest-address', lat, lng],
    () =>
      fetchAddressByGeocode({
        at: `${lat},${lng}`,
      }),
    { ...options, select: (data) => data.items[0], enabled },
  );
};
