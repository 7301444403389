export const COUNTIES = [
  { id: 'B', label: 'București' },
  { id: 'AB', label: 'Alba' },
  { id: 'AG', label: 'Argeș' },
  { id: 'AR', label: 'Arad' },
  { id: 'BC', label: 'Bacău' },
  { id: 'BH', label: 'Bihor' },
  { id: 'BN', label: 'Bistrița Năsăud' },
  { id: 'BR', label: 'Brăila' },
  { id: 'BT', label: 'Botoșani' },
  { id: 'BV', label: 'Brașov' },
  { id: 'BZ', label: 'Buzău' },
  { id: 'CJ', label: 'Cluj' },
  { id: 'CL', label: 'Călărași' },
  { id: 'CS', label: 'Caraș Severin' },
  { id: 'CT', label: 'Constanța' },
  { id: 'CV', label: 'Covasna' },
  { id: 'DB', label: 'Dâmbovița' },
  { id: 'DJ', label: 'Dolj' },
  { id: 'GJ', label: 'Gorj' },
  { id: 'GL', label: 'Galați' },
  { id: 'GR', label: 'Giurgiu' },
  { id: 'HD', label: 'Hunedoara' },
  { id: 'HR', label: 'Harghita' },
  { id: 'IF', label: 'Ilfov' },
  { id: 'IL', label: 'Ialomița' },
  { id: 'IS', label: 'Iași' },
  { id: 'MH', label: 'Mehedinți' },
  { id: 'MM', label: 'Maramureș' },
  { id: 'MS', label: 'Mureș' },
  { id: 'NT', label: 'Neamț' },
  { id: 'OT', label: 'Olt' },
  { id: 'PH', label: 'Prahova' },
  { id: 'SB', label: 'Sibiu' },
  { id: 'SJ', label: 'Sălaj' },
  { id: 'SM', label: 'Satu Mare' },
  { id: 'SV', label: 'Suceava' },
  { id: 'TL', label: 'Tulcea' },
  { id: 'TM', label: 'Timiș' },
  { id: 'TR', label: 'Teleorman' },
  { id: 'VL', label: 'Vâlcea' },
  { id: 'VN', label: 'Vrancea' },
  { id: 'VS', label: 'Vaslui' },
] as const;
