import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useIdentifyPlantMutation } from '../mutations';
import { useOpenIdentifyPlantResultsDialog } from '../store';

export function Dropzone() {
  const openIdentifyPlantResultsDialog = useOpenIdentifyPlantResultsDialog();
  const {
    mutate: identifyPlant,
    isLoading,
    isError,
  } = useIdentifyPlantMutation({
    onSuccess: (data) => openIdentifyPlantResultsDialog(data),
  });

  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      if (!acceptedFiles.length) return;

      identifyPlant(acceptedFiles);
    },
    [identifyPlant],
  );

  const { getInputProps, getRootProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/heic': [],
      'image/heif': [],
    },
    onDropAccepted,
  });

  return (
    <Box
      bgcolor='grey.50'
      border={2}
      borderColor='grey.300'
      borderRadius={1}
      maxWidth='sm'
      position='relative'
      px={{ xs: 2, sm: 13 }}
      py={{ xs: 2, sm: 8 }}
      sx={{ cursor: 'pointer' }}
      width={1}
      {...getRootProps()}
    >
      <Stack
        alignItems='center'
        border='2px dashed'
        borderColor='grey.300'
        borderRadius={1}
        color='grey.500'
        px={1}
        py={6}
      >
        <AddPhotoAlternateOutlinedIcon color='inherit' fontSize='large' />
        <Box fontSize={14} textAlign='center'>
          <Typography
            color='primary'
            component='span'
            fontSize='inherit'
            fontWeight={600}
          >
            Încarcă un fișier
          </Typography>{' '}
          <Typography color='grey.700' component='span' fontSize='inherit'>
            sau adaugă-l direct aici
          </Typography>
        </Box>
        <Typography fontSize={12}>PNG, JPG maximum 50MB</Typography>
        <Typography
          color='warning.main'
          fontSize={12}
          minHeight={18}
          textAlign='center'
        >
          {isError &&
            'Am întâmpinat o problemă. Asigurați-vă că încărcați maximum 5 fișiere cu mărimea totală de 50MB și încercați din nou.'}
        </Typography>
      </Stack>
      <input {...getInputProps()} />
      {isLoading && (
        <Stack
          alignItems='center'
          borderRadius={1}
          direction='row'
          gap={2}
          height={1}
          justifyContent='center'
          left={0}
          position='absolute'
          top={0}
          width={1}
          sx={{ backdropFilter: 'blur(6px)' }}
        >
          <CircularProgress size={16} />
          <Typography>Se încarcă fișierele</Typography>
        </Stack>
      )}
    </Box>
  );
}
