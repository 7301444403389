import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { createHoneyFieldsSlice } from './createHoneyFieldsSlice';
import { createIdentifyPlantResultsDialogSlice } from './createIdentifyPlantResultsDialogSlice';
import { createMapSlice } from './createMapSlice';
import {
  HoneyFieldsSlice,
  IdentifyPlantResultsDialogSlice,
  InstallAppStore,
  MapSlice,
} from './types';

export const useStore = create<MapSlice & IdentifyPlantResultsDialogSlice>()(
  (...a) => ({
    ...createMapSlice(...a),
    ...createIdentifyPlantResultsDialogSlice(...a),
  }),
);

export const useInstallAppStore = create<InstallAppStore>()(
  persist(
    (set) => ({
      isInstalled: false,
      setIsInstalled: () => set({ isInstalled: true }),
    }),
    {
      name: 'is-app-installed',
      partialize: (state) => ({ isInstalled: state.isInstalled }),
    },
  ),
);

export const useHoneyFieldsStore = create<HoneyFieldsSlice>()(
  persist(
    (...a) => ({
      ...createHoneyFieldsSlice(...a),
    }),
    {
      name: 'honey-fields',
      partialize: (state) => ({ honeyFields: state.honeyFields }),
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
