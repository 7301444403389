import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { fetchBeekeepers } from '../services';
import { Beekeeper, FetchApiResponse } from '../types';

export function useBeekeepersQuery(
  { associativeForm, county }: { associativeForm?: string; county?: string },
  options?: UseInfiniteQueryOptions<
    FetchApiResponse<Beekeeper>,
    Error,
    FetchApiResponse<Beekeeper>,
    FetchApiResponse<Beekeeper>,
    string[]
  >,
) {
  const enabled = options?.enabled ?? true;

  return useInfiniteQuery(
    ['beekeepers', county ?? '', associativeForm ?? ''],
    fetchBeekeepers,
    {
      ...options,
      getNextPageParam: (lastPage) => {
        if (!lastPage.next) return null;

        const queryString = lastPage.next?.replace(/^.*\?/, '');
        const queryParams = new URLSearchParams(queryString);
        return queryParams.get('offset');
      },
      enabled,
    },
  );
}
