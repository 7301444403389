import { BasicMapPoint, HoneyField } from '../types';
import {
  createDomMarkerAtPosition,
  createMapIcon,
  plantMapMarkerSvg,
  treeMapMakerSvg,
} from './mapObjectsUtils';
import { isDomMarker } from './typeGuards';

const DEFAULT_POINT_WEIGHT = 1;

const defaultClusteringOptions: H.clustering.Provider.ClusteringOptions = {
  eps: 20,
  minWeight: 3,
};

export const buildClusterLayer = <T extends BasicMapPoint>(
  data: T[],
  onClusterMarkerTap: (
    marker: H.map.DomMarker,
    honeyFields: HoneyField[],
  ) => void,
  onNoiseMarkerTap: (marker: H.map.DomMarker) => void,
) => {
  const dataPoints = data.map(
    (item) =>
      new H.clustering.DataPoint(
        parseFloat(item.lat),
        parseFloat(item.lng),
        DEFAULT_POINT_WEIGHT,
        item,
      ),
  );

  const clusteredDataProvider = new H.clustering.Provider(dataPoints, {
    clusteringOptions: defaultClusteringOptions,
  });

  const defaultTheme = clusteredDataProvider.getTheme();
  const customTheme: H.clustering.ITheme = {
    getClusterPresentation: (cluster) => {
      const clusterMarker = defaultTheme.getClusterPresentation.call(
        defaultTheme,
        cluster,
      );

      clusterMarker.addEventListener('tap', (event: H.mapevents.Event) => {
        event.stopPropagation();
        const honeyFields: HoneyField[] = [];
        cluster.forEachDataPoint((dataPoint) =>
          honeyFields.push(dataPoint.getData()),
        );

        const { target } = event;
        if (isDomMarker(target)) {
          onClusterMarkerTap(target, honeyFields);
        }
      });

      return clusterMarker;
    },
    getNoisePresentation: (noisePoint) => {
      const { lifecycle } = noisePoint.getData();
      const isAnualPlant = lifecycle === 'A';
      const svgMarker = isAnualPlant ? plantMapMarkerSvg : treeMapMakerSvg;
      const iconColor = isAnualPlant ? '#F99771' : '#0BC58B';

      const icon = createMapIcon(svgMarker, {
        color: iconColor,
        marginLeft: '-7px',
        marginTop: '-40px',
      });
      const noiseMarker = createDomMarkerAtPosition(noisePoint.getPosition(), {
        icon,
        min: noisePoint.getMinZoom(),
        data: noisePoint,
      });

      noiseMarker.addEventListener('tap', (event: H.mapevents.Event) => {
        event.stopPropagation();

        const { target } = event;
        if (isDomMarker(target)) {
          onNoiseMarkerTap(target);
        }
      });

      return noiseMarker;
    },
  };

  clusteredDataProvider.setTheme(customTheme);

  return {
    clusterLayer: new H.map.layer.ObjectLayer(clusteredDataProvider),
    clusterProvider: clusteredDataProvider,
  };
};
