/* eslint-disable camelcase */
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import { Box, Link, Stack, Typography, useTheme } from '@mui/material';
import userAvatar from '../assets/img/user_avatar.png';

type UserInfo = {
  county: string;
  description: string;
  name: string;
  settlement: string;
  phone_number: string;
  email: string;
};

export function UserItem({
  county,
  description,
  name,
  settlement,
  phone_number,
  email,
}: UserInfo) {
  const { typography } = useTheme();
  return (
    <Stack direction={{ xs: 'column', md: 'row' }} columnGap={12} rowGap={8}>
      <Box
        alt='img'
        borderRadius={1}
        component='img'
        maxWidth={180}
        src={userAvatar}
      />
      <Stack gap={2}>
        <Stack alignItems='center' direction='row' gap={2}>
          <Typography fontSize={18} fontWeight={typography.fontWeightBold}>
            {name}
          </Typography>
          {phone_number && (
            <Link href={`tel:${phone_number}`}>
              <LocalPhoneRoundedIcon fontSize='small' />
            </Link>
          )}
          {email && (
            <Link href={`mailto:${email}`}>
              <EmailRoundedIcon fontSize='small' />
            </Link>
          )}
        </Stack>
        <Typography
          color='primary'
          fontSize={18}
          fontWeight={typography.fontWeightBold}
        >
          {settlement && county
            ? `${settlement}, ${county}`
            : settlement ?? county}
        </Typography>
        <Typography>{description}</Typography>
      </Stack>
    </Stack>
  );
}
