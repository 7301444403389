import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import {
  Box,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import androidInstructionsPng from '../assets/img/android_instructions.png';
import androidInstructionsMobilePng from '../assets/img/android_instructions_mobile.png';
import iosInstructionsPng from '../assets/img/ios_instructions.png';
import iosInstructionsMobilePng from '../assets/img/ios_instructions_mobile.png';
import { Divider } from '../components';

export function InstallInstructionsPage() {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <Stack gap={20} py={10}>
      <Box>
        <Typography
          color='primary'
          fontSize={{ xs: 40, md: 52 }}
          textAlign='center'
          variant='h2'
        >
          Instalează aplicația Agroapis
        </Typography>

        <Typography gutterBottom textAlign='center' variant='h6'>
          Pentru a putea accesa și reveni cu ușurință la această aplicație îți
          recomandăm să o instalezi pe telefon. Ulterior va putea fi accesată ca
          orice altă aplicație din telefonul tău. Mai jos vei găsi instrucțiuni
          de instalare pentru sitemele de operare <Link href='#ios'>iOS</Link>{' '}
          și
          <Link href='#android'>Android</Link>.
        </Typography>

        <Divider />
      </Box>

      <Stack>
        <Typography id='ios' gutterBottom variant='h5'>
          Instrucțiuni de instalare pentru telefoane ce folosesc sistemul de
          operare iOS
        </Typography>
        <Typography>
          1. Apăsați pe butonul Partajați (
          <IosShareRoundedIcon color='info' fontSize='small' />) în bara de
          meniu.
        </Typography>
        <Typography gutterBottom>
          2. Derulați în jos lista de opțiuni, apoi apăsați pe{' '}
          <strong>Adăugați la ecranul principal</strong>.
        </Typography>
        <Typography px={5}>
          Dacă nu vedeți opțiunea <strong>Adăugați la ecranul principal</strong>
          , o puteți adăuga. Derulați în jos la capătul listei, apăsați pe
          Editați acțiunile (
          <AddCircleRoundedIcon color='success' fontSize='small' />
          ), apoi apăsați pe <strong>Adăugați la ecranul principal</strong>.
        </Typography>
        <Typography gutterBottom>
          3. Apăsați pe <strong>Adăugați</strong>.
        </Typography>
        <Box borderRadius={2} boxShadow={3} textAlign='center' py={3} mt={3}>
          {isMobile ? (
            <img alt='share' src={iosInstructionsMobilePng} width='100%' />
          ) : (
            <img alt='share' src={iosInstructionsPng} width={640} />
          )}
        </Box>
      </Stack>

      <Stack>
        <Typography id='android' gutterBottom variant='h5'>
          Instrucțiuni de instalare pentru telefoane ce folosesc sistemul de
          operare Android
        </Typography>
        <Typography>Deschideți Chrome pe dispozitivul Android.</Typography>
        <Typography>
          1. Apăsați pe butonul de meniu (
          <MoreVertRoundedIcon fontSize='small' color='action' />
          ).
        </Typography>
        <Typography>
          2. Apăsați pe <strong>Instalați aplicația</strong>.
        </Typography>
        <Typography gutterBottom>
          3. Apăsați pe <strong>Instalează</strong>.
        </Typography>
        <Box borderRadius={2} boxShadow={3} textAlign='center' py={3} mt={3}>
          {isMobile ? (
            <img alt='share' src={androidInstructionsMobilePng} width='100%' />
          ) : (
            <img alt='share' src={androidInstructionsPng} width={640} />
          )}
        </Box>
      </Stack>
    </Stack>
  );
}
