import { concat, filter } from 'lodash';
import { StateCreator } from 'zustand';
import { HoneyFieldsSlice } from './types';

export const createHoneyFieldsSlice: StateCreator<HoneyFieldsSlice> = (
  set,
) => ({
  honeyFields: [],
  updateHoneyFields: (newFields) =>
    set(({ honeyFields }) => {
      const oldHoneyFieldsPks = honeyFields.map(({ pk }) => pk);
      const filteredNewHoneyFields = filter(
        newFields,
        (field) => !oldHoneyFieldsPks.includes(field.pk),
      );

      return { honeyFields: concat(honeyFields, filteredNewHoneyFields) };
    }),
  selectedDate: new Date(),
  setSelectedDate: (selectedDate) => set({ selectedDate }),
  selectedCropCategories: [],
  setSelectedCropCategories: (selectedCropCategories) =>
    set({ selectedCropCategories }),
  isHoneyFieldsHidden: false,
  toggleHoneyFieldsHidden: () =>
    set((prev) => ({ isHoneyFieldsHidden: !prev.isHoneyFieldsHidden })),
});
