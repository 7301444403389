import FullscreenExitRoundedIcon from '@mui/icons-material/FullscreenExitRounded';
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useCallback } from 'react';
import {
  useFullscreen,
  useIsHoneyFieldsHidden,
  useToggleFullScreen,
  useToggleHoneyFieldsHidden,
} from '../store';

export function MapButtons() {
  const isHoneyFieldsHidden = useIsHoneyFieldsHidden();
  const toggleHoneyFieldsHidden = useToggleHoneyFieldsHidden();
  const fullscreen = useFullscreen();
  const toggleFullscreen = useToggleFullScreen();

  const handleToggleFullscreen = useCallback(() => {
    if (!fullscreen) {
      window.scrollTo(0, 0);
    }
    toggleFullscreen();
  }, [fullscreen, toggleFullscreen]);

  return (
    <Box
      bgcolor='white'
      borderRadius={1}
      top={4}
      right={12}
      position='absolute'
      zIndex={1}
    >
      <Tooltip
        title={isHoneyFieldsHidden ? 'Arată câmpurile' : 'Ascunde câmpurile'}
      >
        <IconButton onClick={toggleHoneyFieldsHidden} size='small'>
          {isHoneyFieldsHidden ? (
            <VisibilityRoundedIcon />
          ) : (
            <VisibilityOffRoundedIcon />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip title={fullscreen ? 'Micșorează' : 'Mărește'}>
        <IconButton onClick={handleToggleFullscreen} size='small'>
          {fullscreen ? (
            <FullscreenExitRoundedIcon />
          ) : (
            <FullscreenRoundedIcon />
          )}
        </IconButton>
      </Tooltip>
    </Box>
  );
}
