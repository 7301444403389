import H from '@here/maps-api-for-javascript';
import { useEffect, useRef } from 'react';

export function useInitializeHereMapCore(mapOptions?: H.Map.Options) {
  const mapRef = useRef<HTMLElement>();

  useEffect(() => {
    if (!process.env.REACT_APP_HERE_MAPS_API_KEY || !mapRef.current) {
      return undefined;
    }

    const platform = new H.service.Platform({
      apikey: process.env.REACT_APP_HERE_MAPS_API_KEY,
    });

    const layers = platform.createDefaultLayers();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const map = new H.Map(mapRef.current, layers.vector.normal.map, {
      ...mapOptions,
    });

    if (mapOptions?.center) {
      const centerMarker = new H.map.Marker(mapOptions?.center);
      map.addObject(centerMarker);
    }

    return () => {
      map.dispose();
    };
  }, [mapOptions]);

  return mapRef;
}
