import axios from 'axios';
import { API_URLS } from '../constants';
import { BeekeeperRegistrationForm } from '../types';

export const registerBeekeeper = async (
  registerForm: Omit<BeekeeperRegistrationForm, 'accepted_terms'>,
): Promise<Omit<BeekeeperRegistrationForm, 'accepted_terms'>> => {
  const { data } = await axios.post(API_URLS.BEEKEEPERS, registerForm);

  return data;
};
