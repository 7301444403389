import { useEffect } from 'react';
import { useMap } from '../store';
import { useShowBeeHiveAtPosition } from './useShowBeeHiveAtPosition';

export const useAddMapEvents = () => {
  const map = useMap();
  const showBeeHivePosition = useShowBeeHiveAtPosition();

  useEffect(() => {
    const onMapTap = (event: H.mapevents.Event) => {
      if (!map) return;

      const coord = map.screenToGeo(
        event.currentPointer.viewportX,
        event.currentPointer.viewportY,
      );
      if (coord) {
        showBeeHivePosition(coord);
      }
    };

    map?.addEventListener('tap', onMapTap);

    return () => {
      map?.removeEventListener('tap', onMapTap);
    };
  }, [map, showBeeHivePosition]);
};
