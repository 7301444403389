import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

type DetailsPanelContainerProps = {
  onClose: () => void;
};

export function DetailsPanelContainer({
  children,
  onClose,
}: PropsWithChildren<DetailsPanelContainerProps>) {
  return (
    <Stack
      borderRadius={2}
      boxShadow={4}
      gap={4}
      height={1}
      minWidth={{ xs: 0, sm: 360 }}
      overflow='auto'
      position='relative'
      px={4}
      py={2}
    >
      <IconButton
        onClick={onClose}
        sx={{ position: 'absolute', top: 0, right: 0 }}
      >
        <CloseIcon />
      </IconButton>
      {children}
    </Stack>
  );
}
