import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Divider as MuiDivider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button/Button';
import { grey } from '@mui/material/colors';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AgriculturalistFilters, Divider } from '../components';
import { UserItem } from '../components/UserItem';
import { useBeekeepersQuery } from '../queries';
import { Routes } from '../types';

export function BeekeepersPage() {
  const { typography } = useTheme();
  const navigate = useNavigate();

  const [county, setCounty] = useState<string>();
  const [associativeForm, setAssociativeForm] = useState<string>();

  const {
    data,
    isFetchingNextPage,
    isLoading: isLoadingBeekeepers,
    isError: isErrorLoadingBeekeepers,
    fetchNextPage,
    hasNextPage,
  } = useBeekeepersQuery({ associativeForm, county });

  const beekeepers = useMemo(
    () => data?.pages.flatMap(({ results }) => results) ?? [],
    [data?.pages],
  );

  const isLoading = isLoadingBeekeepers;
  const isError = isErrorLoadingBeekeepers;

  const noBeekeepers = !isLoading && !isError && !beekeepers.length;

  const onRegister = useCallback(
    () => navigate(Routes.Registration, { state: { type: 'beekeeper' } }),
    [navigate],
  );

  return (
    <Stack gap={4} height={1} py={5}>
      <Typography
        color='primary'
        fontSize={{ xs: 40, md: 52 }}
        textAlign='center'
        variant='h2'
      >
        Listă apicultori
      </Typography>
      <Typography textAlign='justify'>
        Ne dorim ca acest website să funcționeze ca un instrument folositor și
        interactiv. Pentru aceasta îți propunem să te înregistrezi în lista de
        apicultori ai proiectului.
      </Typography>
      <Box>
        <Typography textAlign='justify'>
          Ce poți obține prin înregistrarea în această aplicație:
        </Typography>
        <List dense>
          <ListItem>
            <ListItemText primary='- informare despre modificări/actualizări/noi funcționalități ale aplicației;' />
          </ListItem>
          <ListItem>
            <ListItemText primary='- comunicări importante de interes apicol;' />
          </ListItem>
          <ListItem>
            <ListItemText primary='- alerte despre incidente care îți pot afecta stupina (intoxicații în zona ta, urgențe  de natură patologică, etc).' />
          </ListItem>
        </List>
      </Box>
      <Box>
        <Typography textAlign='justify'>
          Ce se așteaptă de la tine ca membru al listei noastre de apicultori:
        </Typography>
        <List dense>
          <ListItem>
            <ListItemText primary='- colaborare pentru ridicarea calității acestei aplicații-orice propunere este binevenită;' />
          </ListItem>
          <ListItem>
            <ListItemText primary='- transmiterea de informații de interes apicol major pentru întreaga comunitate (anunțurile comerciale și publicitatea sunt excluse);' />
          </ListItem>
          <ListItem>
            <ListItemText primary='- în limita disponibilității, competenței și pasiunii, participarea pe criteriile științei participative la îmbogățirea bazei de date a aplicației. Exemplu: dacă sunteți botanist profesionist sau iubitor pasionat al plantelor melifere puteți contribui la îmbunătățirea aplicației de recunoaștere a plantelor melifere (faci o poză a unei plante, mergi la pagina „Acasă”, o încarci în sistem împreună cu datele botanice, melifere și/sau agronomice, ca propunere pentru includere în baza de date comună).' />
          </ListItem>
        </List>
      </Box>
      <Typography
        textAlign='center'
        fontSize={32}
        fontWeight={typography.fontWeightBold}
      >
        Nu te afli pe lista apicultorilor?
      </Typography>
      <Box textAlign='center'>
        <Button
          color='primary'
          fullWidth={false}
          onClick={onRegister}
          variant='contained'
        >
          <Typography
            fontWeight={typography.fontWeightBold}
            textTransform='none'
          >
            Înscrie-te acum
          </Typography>
        </Button>
      </Box>

      <Divider />

      <AgriculturalistFilters
        setAssociativeForm={setAssociativeForm}
        setCounty={setCounty}
      />

      {(isLoading || isError || noBeekeepers) && (
        <Stack
          alignItems='center'
          flexGrow={1}
          gap={1}
          height={1}
          justifyContent='center'
        >
          {isLoading && (
            <>
              <CircularProgress />
              <Typography fontWeight={typography.fontWeightBold}>
                Se încarcă lista de apicultori
              </Typography>
            </>
          )}

          {isError && (
            <>
              <ErrorRoundedIcon color='error' />
              <Typography fontWeight={typography.fontWeightBold}>
                Ne pare rău, am întâmpinat o problemă. Încarcă pagina din nou
                sau revino mai târziu
              </Typography>
            </>
          )}

          {noBeekeepers && (
            <>
              <InfoRoundedIcon color='info' />
              <Typography fontWeight={typography.fontWeightBold}>
                Momentan nu există nici un apicultor înscris în aplicație care
                să corespundă criteriilor selectate. Ești apicultor? Folosește
                butonul de mai sus și înscrie-te.
              </Typography>
            </>
          )}
        </Stack>
      )}

      {(beekeepers?.length ?? 0) > 0 &&
        beekeepers?.map((beekeeper) => (
          <Fragment key={beekeeper.pk}>
            <UserItem {...beekeeper} />
            <MuiDivider sx={{ borderColor: grey[300] }} />
          </Fragment>
        ))}

      {hasNextPage && (
        <Box textAlign='center'>
          <Button
            disabled={isFetchingNextPage}
            onClick={() => fetchNextPage()}
            variant='outlined'
          >
            Arată mai mulți apicultori
          </Button>
        </Box>
      )}
    </Stack>
  );
}
