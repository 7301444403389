import LogRocket from 'logrocket';

export const logRocketInit = () => {
  if (
    process.env.NODE_ENV === 'production' &&
    process.env.REACT_APP_LOG_ROCKET_AGROAPIS_ID
  ) {
    LogRocket.init(process.env.REACT_APP_LOG_ROCKET_AGROAPIS_ID);
  }
};
