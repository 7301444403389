import axios from 'axios';
import { API_URLS } from '../constants';
import { HoneyField } from '../types';

export const addHoneyField = async (
  honeyField: Omit<HoneyField, 'pk'>,
): Promise<HoneyField> => {
  const { data } = await axios.post(API_URLS.HONEY_FIELD, honeyField);

  return data;
};
