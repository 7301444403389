import CloseIcon from '@mui/icons-material/Close';

import {
  Button,
  Container,
  IconButton,
  Link,
  Stack,
  alpha,
  useTheme,
} from '@mui/material';
import { useRef, useState, useEffect } from 'react';
import { Outlet, ScrollRestoration, useNavigate } from 'react-router-dom';
import { Footer, NavigationBar } from '../components';
import { NAVIGATION_BAR_HEIGHT } from '../constants';
import { useInstallAppSlice } from '../store';
import { Routes } from '../types';

const getIsHomeScreenStarted = () => {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  if (document.referrer.startsWith('android-app://')) {
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (navigator.standalone || isStandalone) {
    return true;
  }

  return false;
};

export function Root() {
  const installDismissed = useRef(false);
  const isHomeScreenStarted = getIsHomeScreenStarted();
  const [open, setOpen] = useState(true);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { isInstalled, setIsInstalled } = useInstallAppSlice();

  const handleClose = () => {
    installDismissed.current = true;
    setOpen(false);
  };

  const onInstall = async () => {
    navigate(Routes.InstallInstructions);
  };

  const showInstaller =
    open && !installDismissed.current && !isHomeScreenStarted && !isInstalled;

  useEffect(() => {
    const onInstallApp = () => {
      setIsInstalled();
    };

    window.addEventListener('appinstalled', onInstallApp);

    return () => {
      window.removeEventListener('appinstalled', onInstallApp);
    };
  });

  return (
    <Stack height={1} pt={`${NAVIGATION_BAR_HEIGHT}px`} position='relative'>
      <NavigationBar />
      <Container component='main' sx={{ flexGrow: 1 }}>
        {showInstaller && (
          <Stack
            alignItems='center'
            bgcolor={alpha(palette.primary.light, 0.2)}
            borderRadius={1}
            mt={2}
            px={2}
            py={4}
            position='relative'
          >
            <IconButton
              onClick={handleClose}
              sx={{ position: 'absolute', top: 0, right: 0 }}
            >
              <CloseIcon />
            </IconButton>
            <Link
              href='https://play.google.com/store/apps/details?id=ro.polenizare.agroapis.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
              target='_blank'
              sx={{ height: 60 }}
            >
              <img
                alt='acum pe Google Play'
                src='https://play.google.com/intl/en_us/badges/static/images/badges/ro_badge_web_generic.png'
                style={{ height: '100%' }}
              />
            </Link>
            <Button onClick={onInstall} size='small' variant='text'>
              Vezi instrucțiuni pentru alte dispozitive
            </Button>
          </Stack>
        )}

        <Outlet />
      </Container>
      <Footer />

      <ScrollRestoration />
    </Stack>
  );
}
