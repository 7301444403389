import { Box, Button, Stack, Typography, alpha, useTheme } from '@mui/material';
import { useState } from 'react';

export function MapCountySelectorOverlay() {
  const { palette, zIndex } = useTheme();
  const [showOverlay, setShowOverlay] = useState(true);

  if (!showOverlay) {
    return null;
  }

  return (
    <Box
      alignItems='center'
      bgcolor={alpha(palette.common.black, 0.3)}
      bottom={0}
      display='flex'
      justifyContent='center'
      left={0}
      position='absolute'
      right={0}
      top={0}
      zIndex={zIndex.appBar - 1}
    >
      <Stack
        alignItems='center'
        bgcolor={palette.common.white}
        borderRadius={2}
        display='flex'
        gap={2}
        justifyContent='center'
        width={{ xs: 1, md: 0.5 }}
        p={3}
      >
        <Typography>
          Pentru a începe alegeți un punct pe hartă în județul/localitatea de
          interes sau specificați o adresă în câmpul{' '}
          <strong>&quot;Unde ești acum&quot;</strong> de mai sus
        </Typography>
        <Button onClick={() => setShowOverlay(false)} variant='contained'>
          Ok
        </Button>
      </Stack>
    </Box>
  );
}
