import { omit } from 'lodash';
import { StateCreator } from 'zustand';
import { IdentifyPlantResults } from '../types';
import { IdentifyPlantResultsDialogSlice } from './types';

export const createIdentifyPlantResultsDialogSlice: StateCreator<
  IdentifyPlantResultsDialogSlice
> = (set) => ({
  open: false,
  openDialog: (data: IdentifyPlantResults) => set({ open: true, data }),
  closeDialog: () =>
    set((state) => ({ ...omit(state, 'data'), open: false }), true),
});
