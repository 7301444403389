import { Box, styled } from '@mui/material';
import { memo } from 'react';
import { useInitializeHereMap } from '../hooks';
import { useFullscreen, useMapBehavior } from '../store';
import { MapButtons } from './MapButtons';

type MapProps = {
  height: number | string;
};

const MapContainer = styled(Box)(({ theme }) => ({
  '& > div': {
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[5],
  },
}));

// eslint-disable-next-line react/display-name
export const Map = memo(({ height }: MapProps) => {
  const mapRef = useInitializeHereMap();
  const mapBehavior = useMapBehavior();
  const fullscreen = useFullscreen();

  if (mapBehavior) {
    mapBehavior.disable(
      // eslint-disable-next-line no-bitwise
      H.mapevents.Behavior.Feature.WHEEL_ZOOM |
        H.mapevents.Behavior.Feature.HEADING |
        H.mapevents.Behavior.Feature.TILT |
        H.mapevents.Behavior.Feature.PANNING,
    );
    if (fullscreen) {
      mapBehavior.enable(H.mapevents.Behavior.Feature.WHEEL_ZOOM);
      mapBehavior.enable(H.mapevents.Behavior.Feature.PANNING);
    }
  }

  return (
    <MapContainer
      flexGrow={1}
      height={height}
      ref={mapRef}
      sx={{ position: 'relative' }}
    >
      <MapButtons />
    </MapContainer>
  );
});
