import {
  Alert,
  AlertProps,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Stack,
} from '@mui/material';
import { omit } from 'lodash';
import { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useRegisterBeekeeper } from '../mutations';
import {
  BeekeeperRegistrationForm as BeekeeperRegistrationFormType,
  FormType,
} from '../types';
import { RegistrationForm } from './RegistrationForm';

type BeekeeperRegistrationFormProps = {
  onSwitchFormType: (type: FormType) => void;
};

export function BeekeeperRegistrationForm({
  onSwitchFormType,
}: BeekeeperRegistrationFormProps) {
  const [alert, setAlert] = useState<{
    open: boolean;
    message: string;
    severity: AlertProps['severity'];
  }>();
  const { control, handleSubmit, reset, ...rest } =
    useForm<BeekeeperRegistrationFormType>({
      defaultValues: {
        accepted_terms: false,
        county: '',
        identification_number: '',
        description: '',
        email: '',
        hive_count: 0,
        name: '',
        phone_number: '',
        settlement: '',
        status: 0,
      },
      mode: 'onChange',
    });

  const { mutate: registerBeekeeper, isLoading } = useRegisterBeekeeper();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = useCallback<SubmitHandler<BeekeeperRegistrationFormType>>(
    async (data) => {
      const token = await executeRecaptcha?.();
      if (token) {
        registerBeekeeper(omit(data, 'accepted_terms'), {
          onSuccess: () => {
            reset();
            setAlert({
              open: true,
              message:
                'Îți mulțumim că te-ai înregistrat. Vom valida formularul în cel mai scurt timp',
              severity: 'success',
            });
          },
        });
      }
    },
    [executeRecaptcha, registerBeekeeper, reset],
  );

  return (
    <FormProvider
      control={control}
      handleSubmit={handleSubmit}
      reset={reset}
      {...rest}
    >
      <Stack component='form' onSubmit={handleSubmit(onSubmit)}>
        <RegistrationForm
          isLoading={isLoading}
          lastSectionContent={
            <Grid container>
              <Grid item>
                <Controller
                  control={control}
                  name='hive_count'
                  render={({ field, fieldState }) => (
                    <FormControl>
                      <InputLabel htmlFor={field.name} variant='outlined'>
                        Număr de stupi disponibili:
                      </InputLabel>
                      <OutlinedInput
                        error={Boolean(fieldState.error)}
                        id={field.name}
                        {...field}
                      />
                      <FormHelperText error={Boolean(fieldState.error)}>
                        {fieldState.error?.message ?? ' '}
                      </FormHelperText>
                    </FormControl>
                  )}
                  rules={{
                    maxLength: {
                      value: 100,
                      message: 'Câmpul poate avea maximum 100 de caractere',
                    },
                  }}
                />
              </Grid>
            </Grid>
          }
          lastSectionTitle='Despre stupină'
          onSwitchFormType={onSwitchFormType}
          type='beekeeper'
        />
      </Stack>

      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={6000}
        open={alert?.open}
        onClose={() => setAlert(undefined)}
      >
        <Alert variant='filled' severity={alert?.severity}>
          {alert?.message}
        </Alert>
      </Snackbar>
    </FormProvider>
  );
}
