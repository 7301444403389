import {
  Autocomplete,
  Button,
  ButtonBase,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { COUNTIES } from '../constants';
import { useAssociativeFormsQuery } from '../queries';
import {
  CommonRegistrationForm as CommonRegistrationFormType,
  FormType,
  Routes,
} from '../types';
import { SectionNumber } from './SectionNumber';
import { SectionTitle } from './SectionTitle';

type StyledButtonProps = {
  selected?: boolean;
};

const StyledButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<StyledButtonProps>(({ selected, theme }) => ({
  borderColor: selected ? theme.palette.primary.dark : theme.palette.grey[700],
  borderRadius: theme.spacing(2),
  borderStyle: 'solid',
  borderWidth: 2,
  color: selected ? theme.palette.text.primary : theme.palette.grey[700],
  fontSize: 24,
  fontWeight: selected ? 600 : 400,
  padding: theme.spacing(3, 6),
}));

type RegistrationFormProps = {
  isLoading: boolean;
  lastSectionContent: ReactNode;
  lastSectionNote?: string;
  lastSectionTitle: string;
  onSwitchFormType: (type: FormType) => void;
  type: FormType;
};

export function RegistrationForm({
  isLoading,
  lastSectionContent,
  lastSectionNote,
  lastSectionTitle,
  onSwitchFormType,
  type,
}: RegistrationFormProps) {
  const { control, setValue, trigger } =
    useFormContext<CommonRegistrationFormType>();

  const { data: associativeForms, isLoading: isLoadingAssociativeForms } =
    useAssociativeFormsQuery();

  return (
    <Grid columnSpacing={4} container rowSpacing={12}>
      <Grid item sm={1} xs={2}>
        <SectionNumber label='1' />
      </Grid>
      <Grid item sm={11} xs={10}>
        <Stack gap={3}>
          <SectionTitle title='Completez formularul în calitate de' />

          <Stack
            columnGap={4}
            direction={{ xs: 'column', sm: 'row' }}
            flexWrap='wrap'
            rowGap={10}
          >
            <StyledButton
              onClick={() => onSwitchFormType('beekeeper')}
              selected={type === 'beekeeper'}
            >
              Apicultor
            </StyledButton>
            <StyledButton
              onClick={() => onSwitchFormType('farmer')}
              selected={type === 'farmer'}
            >
              Fermier
            </StyledButton>
          </Stack>
        </Stack>
      </Grid>

      <Grid item sm={1} xs={2}>
        <SectionNumber label='2' />
      </Grid>
      <Grid item sm={11} xs={10}>
        <Stack gap={3}>
          <SectionTitle
            subtitle='Notă: Câmpurile marcate cu * sunt obligatorii pentru înregistrare.'
            title='Despre tine'
          />

          <Grid columnSpacing={6} container rowSpacing={2}>
            <Grid item sm={6} xs={12}>
              <Controller
                control={control}
                name='name'
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <InputLabel htmlFor={field.name} variant='outlined'>
                      Nume complet*:
                    </InputLabel>
                    <OutlinedInput
                      error={Boolean(fieldState.error)}
                      id={field.name}
                      {...field}
                    />
                    <FormHelperText error={Boolean(fieldState.error)}>
                      {fieldState.error?.message ?? ' '}
                    </FormHelperText>
                  </FormControl>
                )}
                rules={{
                  maxLength: {
                    value: 100,
                    message: 'Numele poate avea maximum 100 de caractere',
                  },
                  required: {
                    value: true,
                    message: 'Acest câmp este obligatoriu',
                  },
                }}
              />
            </Grid>
            <Grid display={{ xs: 'none', sm: 'block' }} item sm={6} xs={0} />

            <Grid item sm={6} xs={12}>
              <Controller
                control={control}
                name='phone_number'
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <InputLabel htmlFor={field.name} variant='outlined'>
                      Telefon:
                    </InputLabel>
                    <OutlinedInput
                      error={Boolean(fieldState.error)}
                      id={field.name}
                      type='tel'
                      {...field}
                    />
                    <FormHelperText error={Boolean(fieldState.error)}>
                      {fieldState.error?.message ?? ' '}
                    </FormHelperText>
                  </FormControl>
                )}
                rules={{
                  pattern: {
                    value: /\d{10}/,
                    message: 'Numărul de telefon nu este valid',
                  },
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <Controller
                control={control}
                name='email'
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <InputLabel htmlFor={field.name} variant='outlined'>
                      Adresa de e-mail*:
                    </InputLabel>
                    <OutlinedInput
                      error={Boolean(fieldState.error)}
                      id={field.name}
                      {...field}
                    />
                    <FormHelperText error={Boolean(fieldState.error)}>
                      {fieldState.error?.message ?? ' '}
                    </FormHelperText>
                  </FormControl>
                )}
                rules={{
                  maxLength: {
                    value: 100,
                    message:
                      'Adresa de email poate avea maximum 100 de caractere',
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Adresa de email nu este validă',
                  },
                  required: {
                    value: true,
                    message: 'Acest câmp este obligatoriu',
                  },
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <Controller
                control={control}
                name='associative_form'
                render={({ field, fieldState }) => (
                  <Autocomplete
                    fullWidth
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.pk === value.pk
                    }
                    loading={isLoadingAssociativeForms}
                    onChange={(_, value) => {
                      setValue(field.name, value?.pk ?? 0);
                      trigger(field.name);
                    }}
                    inputValue={
                      associativeForms?.find(({ pk }) => pk === field.value)
                        ?.name ?? ''
                    }
                    options={associativeForms ?? []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...field}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message ?? ' '}
                        id={field.name}
                        InputLabelProps={{
                          htmlFor: field.name,
                          variant: 'outlined',
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingAssociativeForms ? (
                                <CircularProgress color='inherit' size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                          notched: false,
                        }}
                        label='Forma asociativă din care fac parte'
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option.pk}>
                        {option.name}
                      </li>
                    )}
                    value={
                      associativeForms?.find(({ pk }) => pk === field.value) ??
                      null
                    }
                  />
                )}
                rules={{
                  maxLength: {
                    value: 200,
                    message:
                      'Forma asociativă poate avea maximum 200 de caractere',
                  },
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <Controller
                control={control}
                name='identification_number'
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <InputLabel htmlFor={field.name} variant='outlined'>
                      CUI/CNP:
                    </InputLabel>
                    <OutlinedInput
                      error={Boolean(fieldState.error)}
                      id={field.name}
                      {...field}
                    />
                    <FormHelperText error={Boolean(fieldState.error)}>
                      {fieldState.error?.message ?? ' '}
                    </FormHelperText>
                  </FormControl>
                )}
                rules={{
                  pattern: {
                    value: /\d+/,
                    message: 'CUI/CNP nu este valid',
                  },
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <Controller
                control={control}
                name='settlement'
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <InputLabel htmlFor={field.name} variant='outlined'>
                      Localitate*:
                    </InputLabel>
                    <OutlinedInput
                      error={Boolean(fieldState.error)}
                      id={field.name}
                      {...field}
                    />
                    <FormHelperText error={Boolean(fieldState.error)}>
                      {fieldState.error?.message ?? ' '}
                    </FormHelperText>
                  </FormControl>
                )}
                rules={{
                  maxLength: {
                    value: 100,
                    message: 'Localitatea poate avea maximum 100 de caractere',
                  },
                  required: {
                    value: true,
                    message: 'Acest câmp este obligatoriu',
                  },
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <Controller
                control={control}
                name='county'
                render={({ field, fieldState }) => (
                  <Autocomplete
                    fullWidth
                    options={COUNTIES}
                    onChange={(_, value) => {
                      setValue(field.name, value?.id ?? '');
                      trigger(field.name);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...field}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message ?? ' '}
                        id={field.name}
                        InputLabelProps={{
                          htmlFor: field.name,
                          variant: 'outlined',
                        }}
                        InputProps={{
                          ...params.InputProps,
                          notched: false,
                        }}
                        label='Județ*'
                      />
                    )}
                  />
                )}
                rules={{
                  maxLength: {
                    value: 100,
                    message: 'Județul poate avea maximum 100 de caractere',
                  },
                  required: {
                    value: true,
                    message: 'Acest câmp este obligatoriu',
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name='description'
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <InputLabel htmlFor={field.name} variant='outlined'>
                      Spune-ne mai multe printr-o scurtă descriere:
                    </InputLabel>
                    <OutlinedInput
                      error={Boolean(fieldState.error)}
                      id={field.name}
                      multiline
                      rows={4}
                      {...field}
                    />
                    <FormHelperText error={Boolean(fieldState.error)}>
                      {fieldState.error?.message ?? ' '}
                    </FormHelperText>
                  </FormControl>
                )}
                rules={{
                  maxLength: {
                    value: 250,
                    message: 'Descrierea poate avea maximum 250 de caractere',
                  },
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </Grid>

      <Grid item sm={1} xs={2}>
        <SectionNumber label='3' />
      </Grid>
      <Grid item sm={11} xs={10}>
        <Stack gap={3}>
          <SectionTitle subtitle={lastSectionNote} title={lastSectionTitle} />

          {lastSectionContent}
        </Stack>
      </Grid>

      <Grid display={{ xs: 'none', sm: 'block' }} item sm={1} xs={0} />
      <Grid item sm={11} xs={12}>
        <Stack>
          <Controller
            control={control}
            name='accepted_terms'
            render={({ field, fieldState }) => (
              <FormControl>
                <FormControlLabel
                  control={<Checkbox checked={field.value} {...field} />}
                  label={
                    <Typography textAlign='justify'>
                      Prin această bifă îți exprimi acordul ca datele furnizate
                      de tine prin acest formular să fie procesate exclusiv in
                      scopul de a încărca în platformă acest document și ca
                      echipa ROMAPIS să te contacteze doar în legătură cu
                      această submisie. Aici puteți găsi{' '}
                      <Typography
                        color='#198ECF'
                        component={Link}
                        target='_blank'
                        to={Routes.Terms}
                        sx={{ textDecoration: 'none' }}
                      >
                        regulamentul nostru cu privire la prelucrarea datelor cu
                        caracter personal
                      </Typography>
                      .
                    </Typography>
                  }
                />
                <FormHelperText error={Boolean(fieldState.error)}>
                  {fieldState.error?.message ?? ' '}
                </FormHelperText>
              </FormControl>
            )}
            rules={{
              required: {
                value: true,
                message: 'Acest câmp este obligatoriu',
              },
            }}
          />
          <Controller
            control={control}
            name='status'
            render={({ field, fieldState }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(field.value)}
                      {...field}
                      onChange={(event) =>
                        setValue('status', Number(event.target.checked))
                      }
                    />
                  }
                  label={
                    <Typography textAlign='justify'>
                      Sunt de acord cu publicarea datelor mele de contact pentru
                      a putea fi solicitat pentru eventuale propuneri de
                      polenizare sau deplasarea stupinei la culturi agricole.
                    </Typography>
                  }
                />
                <FormHelperText error={Boolean(fieldState.error)}>
                  {fieldState.error?.message ?? ' '}
                </FormHelperText>
              </FormControl>
            )}
            rules={{
              required: {
                value: true,
                message: 'Acest câmp este obligatoriu',
              },
            }}
          />
        </Stack>
      </Grid>

      <Grid display={{ xs: 'none', sm: 'block' }} item sm={10} xs={0} />
      <Grid item sm={2} xs={12} textAlign='right'>
        <Button
          fullWidth
          disabled={isLoading}
          type='submit'
          variant='contained'
          sx={{ minWidth: 88 }}
        >
          {!isLoading ? 'Salvează' : <CircularProgress size={24.5} />}
        </Button>
      </Grid>
    </Grid>
  );
}

RegistrationForm.defaultProps = {
  lastSectionNote: '',
};
