import axios from 'axios';
import { IdentifyPlantResults } from '../types';

const BASE_URL = 'https://my-api.plantnet.org';
const IDENTIFY = '/v2/identify/all';

export const identifyPlant = async (
  images: File[],
): Promise<IdentifyPlantResults> => {
  const queryParams = new URLSearchParams({
    'api-key': process.env.REACT_APP_PLANT_NET_API_KEY ?? '',
    'include-related-images': 'true',
    lang: 'ro',
    'no-reject': 'false',
  });

  const bodyFormData = new FormData();

  images.forEach((image) => bodyFormData.append('images', image));

  const { data } = await axios.post(
    `${BASE_URL}${IDENTIFY}?${queryParams.toString()}`,
    bodyFormData,
    { headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' } },
  );

  return data;
};
